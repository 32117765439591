import { api_url } from "../config/config";
import { authorizedHttpJson } from "../config/http";




export async function getCart(countryCode) {
  return await authorizedHttpJson.get(`${api_url}/v2/cart`,   {
    headers: {
      'country-code': countryCode 
    }
  });
}

export async function getCartTotal(countryCode) {
    return await authorizedHttpJson.get(`${api_url}/v2/cart/total`,   {
      headers: {
        'country-code': countryCode 
      }
    });
  }

  export async function AddProductToCart(data) {
    return await authorizedHttpJson.post(`${api_url}/v2/cart`,data);
  }

  export async function EditProductInCart(data,id) {
    return await authorizedHttpJson.put(`${api_url}/v2/cart/${id}`,data);
  }
  export async function DeleteProductFromCart(id) {
    return await authorizedHttpJson.delete(`${api_url}/v2/cart/${id}`);
  }