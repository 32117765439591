import React from "react";
import { Link } from "react-router-dom";
import new_label from "../assets/images/new 1.svg"; // Ensure this path is correct

const ServiceCard = ({ image, title, link, onClick, isNew }) => {
  return (
    <Link to={link} className="w-[50%] md:w-[30%]" onClick={onClick}>
      <div className="relative bg-white p-4 rounded-xl shadow-lg text-center cursor-pointer">
        {/* New Label */}
        {isNew && (
          <img src={new_label} alt="New" className="absolute top-0 left-0 w-12 h-12" />
        )}
        <div className="flex justify-center items-center w-full">
        {/* Service Image */}
        <img src={image} alt={title} className="w-3/4 mb-3" />
        </div>
        {/* Service Title */}
        <p className="text-primary-500 font-bold">{title}</p>
        
      </div>
    </Link>
  );
};

export default ServiceCard;
