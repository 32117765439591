import React, { useState, useRef, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import paymentheader from "../assets/images/payment-header.png";
import camera from "../assets/images/camera.png";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import BankSelect from "../components/BankSelect";
import PaymentMethod from "../components/PaymentMethod";
import { faTimes, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { checkCoupon } from "../api/orders";
import { getProducts } from "../api/products";
import { getCart, getCartTotal } from "../api/cart";
import { createOrder } from "../api/orderV2";
import { useDispatch } from "react-redux";
import HyperpayPaymentModal from "../components/Modals/HyperpayPaymentModalV2";
import PaymentSuccessV2 from "../components/Modals/PaymentSuccessV2";
import PaymentFail from "../components/Modals/PaymentFail";
import OrderDetailsAccordion from "../components/OrderDetailsAccordion";
import { useLocation, useNavigate } from "react-router-dom";
import LoginModal from "../components/Modals/LoginModal"; // Import the LoginModal
import mosqueIcon from "../assets/images/mosqueIcon.svg";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { trackEvent, trackPurchase } from "../utils/analytics"; // Import GTM tracking function
const PaymentV2 = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const language = useSelector((state) => state.language.language);
  const isLoggedIn = useSelector((state) => state.auth.isAuthenticated);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showCheckoutModal, setShowCheckoutModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showFailureModal, setShowFailureModal] = useState(false);
  const [checkoutId, setCheckoutId] = useState(null);
  const [selectedBankId, setSelectedBankId] = useState(null);
  const [selectedMethod, setSelectedMethod] = useState("ApplePay");
  const [agreeTerms, setAgreeTerms] = useState(false);
  const [products, setProducts] = useState([]);
  const [uploadedPhoto, setUploadedPhoto] = useState(null);
  const [photoPreview, setPhotoPreview] = useState(null);
  const fileInputRef = useRef(null);
  const totalValues = " ";
  const [totalAfterCoupon, setTotalAfterCoupon] = useState(0);
  const [couponCode, setCouponCode] = useState(""); // Coupon code state
  const [couponError, setCouponError] = useState(""); // Error state for coupon code
  const [isCouponApplied, setIsCouponApplied] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [purchaseAttempted, setPurchaseAttempted] = useState(false);
  const selectedCountryCode = useSelector((state) => state.country.countryCode);
  const [cartItems, setCartItems] = useState([]);
  const [cartTotal, setCartTotal] = useState(null);
  const [orderId, setOrderId] = useState(null);

  //  get cart data
  const fetchCart = async () => {
    try {
      const cartData = await getCart(selectedCountryCode);
      setCartItems(cartData.data.data);
      //console.log(cartData);
    } catch (error) {
      //console.error("Error fetching cart:", error);
    }
  };
  const fetchCartTotal = async () => {
    try {
      const total = await getCartTotal(selectedCountryCode);
      //console.log(total);
      setCartTotal(total.data.data); // Assuming API returns { total: amount }
    } catch (error) {
     // console.error("Error fetching cart total:", error);
    }
  };

  useEffect(() => {
    fetchCart();
    fetchCartTotal();
  }, [selectedCountryCode]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search); // Parse the URL parameters
    const paymentStatus = queryParams.get("status"); // Get the 'status' parameter
    const error = queryParams.get("error"); // Get the 'error' parameter
    const orderId=queryParams.get("orderId");
    setOrderId(orderId)

    if (paymentStatus === "success") {
      setShowSuccessModal(true); // Show success modal
      setShowFailureModal(false);
    } else if (paymentStatus === "failed") {
      setShowFailureModal(true); // Show failure modal
      setShowSuccessModal(false);
      setErrorMessage(error);
    }
  }, [location.search]);

  const handleCloseModal = () => {
    const params = new URLSearchParams(location.search);
    params.delete("status"); // Remove the 'status' parameter
    params.delete("error");
    params.delete("orderId");
    navigate({
      pathname: location.pathname,
      search: params.toString(),
    });
    setShowSuccessModal(false);
    setShowFailureModal(false);
  };

  const handleBankSelection = (bankId) => setSelectedBankId(bankId);
  const handleMethodSelect = (method) => setSelectedMethod(method);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      // Set the uploaded photo file for the API
      setUploadedPhoto(file);
      // Create a preview URL
      const reader = new FileReader();
      reader.onloadend = () => setPhotoPreview(reader.result);
      reader.readAsDataURL(file);
    }
  };
  const handlePhotoRemove = () => {
    setUploadedPhoto(null);
    setPhotoPreview(null);
    fileInputRef.current.value = "";
  };

  const handleApplyCoupon = async () => {
    if (!couponCode.trim()) {
      setCouponError(t("enterCouponCode")); // Set the error message if the field is empty
      return;
    }

    const couponData = {
      // product_id: "",
      total: cartTotal?.grand_total,
      code: couponCode,
      shipping: cartTotal?.delivery_cost,
    };

    try {
      const response = await checkCoupon(couponData);

      if (response.status) {
        const { total, discount_value, value, type } = response.data.data;
        if (response.data.status) {
          setTotalAfterCoupon(total); // Update total after coupon
          setIsCouponApplied(true); // Mark coupon as applied
        } else {
          setCouponError(response.data.message || t("invalidCoupon"));
          setIsCouponApplied(false); // Reset coupon applied state
        }
      } else {
        setCouponError(response.data.message || t("invalidCoupon"));
        setIsCouponApplied(false); // Reset coupon applied state
      }
    } catch (error) {
      setIsCouponApplied(false);
      setCouponError(t("somethingWentWrong"));
    }
  };

  const handlePurchase = async () => {
    // Check if user is logged in
    if (!isLoggedIn) {
      setPurchaseAttempted(true);
      setShowLoginModal(true); // Show the login modal
      return; // Stop further execution
    }
  
    // Check if the policy option is agreed
    if (!agreeTerms) {
      toast.error(t("pleaseCheckThePolicy")); // Show toast notification
      return; // Stop further execution
    }
    if (selectedMethod === "bank" && !selectedBankId) {
      toast.error(t("pleaseSelectBank")); // Show error message
      return;
    }
  
    // ✅ Check if "Bank" is selected but no photo is uploaded
    if (selectedMethod === "bank" && !uploadedPhoto) {
      toast.error(t("pleaseUploadTransferPhoto")); // Show error message
      return;
    }



    // ✅ Send "Initiate Checkout" Event to GTM
    trackEvent("initiate_checkout", {
      country: selectedCountryCode, // Change if needed
      value: cartTotal?.grand_total,
      method: selectedMethod,
      items: cartItems.map((item) => ({
        item_name: item.name,
        item_id: item.id,
        price: item.price,
        quantity: item.quantity,
      })),
      payment_method: selectedMethod,
    });

   
 
  


    setLoading(true); // Start loader

    if (selectedMethod === "bank") {
      const bankPaymentData = {
        payment_method: "bank",
        bank_transaction: uploadedPhoto,
      };
      try {
        console.log(selectedCountryCode);
        const paymentByBankResponse = await createOrder(bankPaymentData , selectedCountryCode);
        trackPurchase(paymentByBankResponse.data.data)
        console.log(paymentByBankResponse);
        //trackPurchase(paymentByBankResponse);
        if (paymentByBankResponse.data.status === true) {
          setShowSuccessModal(true); // Show success modal
        }
      } catch (error) {
        console.error("Payment error:", error);
      }
    } else if (selectedMethod === "knet") {
      try {
        const knetResponse = await createOrder({ payment_method: "knet" },selectedCountryCode);
        if (knetResponse.status) {
          trackPurchase(knetResponse.data.data)
          // Redirect user to the KNET payment URL
          window.location.href = knetResponse.data.data.url;
        }
      } catch (error) {
        console.error("KNET payment error:", error);
      }

    }else if (selectedMethod === "tap") {
  try {
    const tapResponse = await createOrder({ payment_method: 'tap'  },selectedCountryCode);
    if (tapResponse.status) {
      // Open the payment URL in a new tab
      trackPurchase(tapResponse.data.data)

      window.open(tapResponse.data.payment_url, "_blank");
    }
  } catch (error) {
    console.error("KNET payment error:", error);
  }
}else {
      // Map selectedMethod to corresponding payment method names
      const paymentMethodMap = {
        ApplePay: "apple_pay",
        Mada: "mada",
        StcPay: "stc",
        VisaMaster: "visa",
      };
  
      const mappedMethod = paymentMethodMap[selectedMethod] || selectedMethod;
  
      try {
        const hyperpayResponse = await createOrder({ payment_method: mappedMethod }, selectedCountryCode);
        trackPurchase(hyperpayResponse.data.data)
        const checkoutId = hyperpayResponse.data.checkout_id;
        setCheckoutId(checkoutId);
        setShowCheckoutModal(true);
      } catch (error) {
        console.error("Hyperpay error:", error);
      }
    }
  
    setLoading(false); // Stop loader
  };
  

  const handleLoginSuccess = () => {
    // Simulate logging in successfully
    setShowLoginModal(false);
  };

  // Effect to handle purchase attempt after login state changes
  useEffect(() => {
    if (isLoggedIn && purchaseAttempted) {
      setPurchaseAttempted(false); // Reset the flag
      handlePurchase(); // Trigger the purchase
    }
  }, [isLoggedIn, purchaseAttempted]);

  const PaymentSummary = () => (
    <div className="bg-primary-500 bg-opacity-20 p-4 rounded-lg">
      <div className="flex justify-between mb-2">
        <p>{t("totalBeforeVAT")}</p>
        <p>{cartTotal?.total_amount}</p>
      </div>
      <div className="flex justify-between mb-2">
        <p>{t("valueAdded")}</p>
        <p>{cartTotal?.tax}</p>
      </div>
      <div className="flex justify-between">
        <p>{t("shippingCost")}</p>
        <p>{cartTotal?.delivery_cost}</p>
      </div>
      <hr className="my-3" />
      <div className="flex justify-between font-bold">
        <p>{t("total")}</p>
        <p>
          {/* Conditional rendering based on whether a coupon has been applied */}
          {isCouponApplied ? totalAfterCoupon : cartTotal?.grand_total}{" "}
          {language == "en" ? cartTotal?.currency_en : cartTotal?.currency}
        </p>
      </div>
    </div>
  );

  return (
    <div className="bg-gray-10 flex flex-col items-center min-h-screen pb-10">
      <div className="w-[90%] sm:w-[80%] bg-white rounded-lg shadow-md">
        <img
          src={paymentheader}
          alt="service type header"
          className="w-[100%] mx-auto mb-5"
        />
        <div className="text-center mb-5">
          <p className="font-bold text-xl sm:text-2xl">{t("payment")}</p>
        </div>
      </div>

      <div className="flex flex-col md:flex-row justify-between gap-8 w-[90%] sm:w-[80%] mt-10">
        <div className="w-full md:w-1/2 bg-white rounded-lg p-4">
          <div className="flex flex-col md:grid md:grid-cols-2 gap-4">
            <PaymentMethod
              selectedMethod={selectedMethod}
              handleSelect={handleMethodSelect}
            />
          </div>
          {selectedMethod === "bank" && (
            <>
              <BankSelect onBankSelect={handleBankSelection} />
              <div className="mt-5">
                <span className="font-bold"> {t("attachTransferPhoto")}</span>
                <div className="flex gap-20">
                  <div className="cursor-pointer border rounded-lg p-3 mt-3 flex items-center">
                    <img
                      src={camera}
                      alt="Attach Image"
                      className="w-10"
                      onClick={() =>
                        document.getElementById("file-input").click()
                      }
                    />
                    <input
                      type="file"
                      id="file-input"
                      accept="image/*"
                      style={{ display: "none" }}
                      ref={fileInputRef}
                      onChange={handleFileChange}
                    />
                  </div>
                  {photoPreview && (
                    <div className="relative">
                      <img
                        src={photoPreview}
                        alt="Uploaded"
                        className="mt-2 w-20 h-20"
                      />
                      <button
                        className="absolute top-0 right-0 bg-gray-30 text-white rounded-full px-1"
                        onClick={handlePhotoRemove}
                      >
                        <FontAwesomeIcon icon={faTimes} />
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </>
          )}

          <div className="flex items-center gap-2 mt-5">
            <input
              type="checkbox"
              id="terms"
              checked={agreeTerms}
              onChange={() => setAgreeTerms(!agreeTerms)}
            />
            <label htmlFor="terms" className="text-sm">
              {t("haveReviewed")}{" "}
              <Link to="/terms-and-conditions" className="text-primary-500">
                {t("privacyPolicy")}
              </Link>
            </label>
          </div>
        </div>

        <div className="w-full md:w-1/2 flex flex-col gap-4">
        
          {/* <div className="flex gap-2">
            <input
              type="text"
              value={couponCode}
              onChange={(e) => {
                setCouponCode(e.target.value);
                if (couponError) setCouponError(""); // Clear error when user starts typing
              }}
              placeholder={t("enterDiscountCode")}
              className="flex-grow p-3 border rounded-lg"
            />
            <button
              onClick={handleApplyCoupon}
              className="bg-primary-500 bg-opacity-[20%] text-primary-500 p-3 rounded-lg w-1/4"
            >
              {t("submit")}
            </button>
          </div>
          {couponError && <p className="text-red-500 my-2">{couponError}</p>} */}

          {/* Cart Products Items List */}
          <OrderDetailsAccordion
            cartItems={cartItems}
            mosqueIcon={mosqueIcon}
          />
          {/* payment summery component */}
          <PaymentSummary />

          <button
            className="bg-primary-500 text-white p-4 rounded-lg w-full mt-4"
            onClick={handlePurchase} // Add this to call handlePurchase when clicked
            disabled={loading}
          >
            {loading ? (
              <div className="flex justify-center items-center">
                <FontAwesomeIcon icon={faSpinner} spin className="mx-2" />
                <span className="">{t("processingPurchase")}</span>
              </div>
            ) : (
              <>
                {t("purchase")}{" "}
                {isCouponApplied ? totalAfterCoupon : cartTotal?.grand_total}{" "}
                {language == "en"
                  ? cartTotal?.currency_en
                  : cartTotal?.currency}
              </>
            )}
          </button>
        </div>
      </div>

      {showCheckoutModal && (
        <HyperpayPaymentModal
          selectedMethod={selectedMethod}
          showCheckoutModal={showCheckoutModal}
          onClose={() => setShowCheckoutModal(false)}
          checkoutId={checkoutId}
          total={cartTotal?.grand_total}
        />
      )}
      {showSuccessModal && <PaymentSuccessV2 onClose={handleCloseModal} orderId={orderId} />}

      {showFailureModal && (
        <div>
          <PaymentFail onClose={handleCloseModal} errorMessage={errorMessage} />
        </div>
      )}

      {showLoginModal && (
        <LoginModal
          showModal={showLoginModal}
          setShowModal={setShowLoginModal}
          handleLoginSuccess={handleLoginSuccess}
        />
      )}
      <ToastContainer />
    </div>
  );
};

export default PaymentV2;
