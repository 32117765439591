import { api_url } from "../config/config";
import { authorizedHttpJson, authorizedHttpForm } from "../config/http";




export async function createOrder(data,countryCode) {
 
  console.log(data);
  console.log('order '+countryCode);
  return await authorizedHttpJson.post(
    `${api_url}/v2/order`, 
    data,
    {
      headers: {
        'country-code': countryCode // Add this custom header
      }
    }
  );
}

  export async function checkPaymentStatus(data,countryCode) {
    return await authorizedHttpJson.put(`${api_url}/v2/check_payment`,data,{
      headers: {
        'country-code': countryCode // Add this custom header
      }
    });
  }
  
  export async function getOrderDetails(id) {
    console.log('order id'+id);
    return await authorizedHttpJson.get(`${api_url}/v2/order/${id}`);
  }
  

