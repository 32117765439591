import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons"; // FontAwesome icons
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const OrderDetailsAccordion = ({ cartItems, mosqueIcon }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(true);
   const isArabic = useSelector((state) => state.language.language) === "ar"; 

  return (
    <div className="mt-4 border border-[#00000033] rounded-lg p-4 bg-white">
      {/* Accordion Header */}
      <div
        className="flex justify-between items-center cursor-pointer"
        onClick={() => setIsOpen(!isOpen)}
      >
        <p className="font-bold">{t("orderDetails")}</p>
        {isOpen ? <FontAwesomeIcon icon={faChevronUp} className="text-gray-500" /> : <FontAwesomeIcon icon={faChevronDown} className="text-gray-500" />}
      </div>

      {/* Accordion Content */}
      {isOpen && (
        <div className="w-full max-w-3xl mt-5">
          {cartItems.map((item) => (
            <div
              key={item.id}
              className="bg-[#0804FC1A] rounded-lg p-4 shadow-md mb-4 mx-5"
            >
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-4">
                  <img
                    src={item.product.photo}
                    alt={item.product.name}
                    className="w-28 h-auto rounded-md"
                  />
                  <div>
                    <p className="text-lg font-semibold">{isArabic? item.product.name : item.product.slug}</p>
                    <span className="mx-3 text-lg font-semibold">{item.qty}</span>
                  </div>
                </div>

                <div className="text-right">
                  <p className="text-lg font-semibold text-gray-700">
                    {item.product.price} {isArabic? item.product.currency : item.product.currency_en }
                  </p>
                </div>
              </div>

              <hr className="my-4 border-gray-300" />

              <div className="flex items-center gap-2">
                <img src={mosqueIcon} alt="Mosque Icon" className="w-8 h-8" />
                <p className="text-sm">{item?.place_name}</p>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default OrderDetailsAccordion;
