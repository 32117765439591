import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import TagManager from 'react-gtm-module';

// Initialize Google Tag Manager
const tagManagerArgs = {
  gtmId: "GTM-MK66RLNC", // Replace with your GTM ID
};

TagManager.initialize(tagManagerArgs);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);

// If you want to start measuring performance in your app
reportWebVitals();
