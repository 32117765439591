import { api_url } from "../config/config";
import { authorizedHttpJson, authorizedHttpForm } from "../config/http";




export async function createOrder(data,countryCode) {
  return await authorizedHttpJson.post(
    `${api_url}/orders/store_web`, 
    data,
    {
      headers: {
        'country-code': countryCode // Add this custom header
      }
    }
  );
}

export async function updateOrderForBank(data) {
    return await authorizedHttpForm.post(`${api_url}/orders/update`,data);
  }

export async function getHyperpayCheckoutId(data,countryCode) {
    return await authorizedHttpJson.post(`${api_url}/orders/checkout-id`,data,{
      headers: {
        'country-code': countryCode // Add this custom header
      }
    });
  }
  
export async function getCheckPaymentStatus(data,countryCode) {
    return await authorizedHttpJson.post(`${api_url}/orders/check-payment-status`,data,{
      headers: {
        'country-code': countryCode // Add this custom header
      }
    });
  }
  
export async function payWithKnet(data) {
    return await authorizedHttpJson.post(`${api_url}/orders/knet`,data);
  }
  
export async function checkCoupon(data) {
    return await authorizedHttpJson.post(`${api_url}/orders/check-coupon`,data);
  }