import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOutAlt, faUser, faBars, faShoppingCart } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import logo from "../assets/images/logo.png";
import { useTranslation } from "react-i18next";
import CurrencySelect from "./CurrencySelector";
import { logout } from "../redux/authSlice";
import LoginModal from "../components/Modals/LoginModal";
import { getCartTotal } from "../api/cart";
import eventBus from "../utils/EventBus";

const Navbar = ({ onLanguageChange }) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const selectedCountryCode = useSelector((state) => state.country.countryCode);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [cartTotal, setCartTotal] = useState(0);
  const [cartCurrency, setCartCurrency] = useState("");

  // Fetch Cart Total
  const fetchCartTotal = async () => {
    try {
      const response = await getCartTotal(selectedCountryCode);
      if (response.data.data.total_amount > 0) {
        setCartTotal(response.data.data.total_amount);
        setCartCurrency(response.data.data.currency);
      } else {
        setCartTotal(0);
      }
    } catch (error) {
      console.error("Error fetching cart total:", error);
    }
  };

  useEffect(() => {
    fetchCartTotal(); // Initial fetch

    // Listen for cart updates
    const handleCartUpdate = () => {
      fetchCartTotal();
    };

    eventBus.addEventListener("cartUpdated", handleCartUpdate);

    return () => {
      eventBus.removeEventListener("cartUpdated", handleCartUpdate);
    };
  }, [selectedCountryCode]);

  const handleLogout = () => {
    dispatch(logout());
  };

  const handleLoginClick = () => {
    setShowLoginModal(true);
  };

  const handleLoginSuccess = () => {
    setShowLoginModal(false);
  };

  return (
    <>
      {/* Fixed Navbar */}
      <nav className="fixed top-0 left-0 w-full flex flex-row items-center justify-between py-4 px-6 md:px-20 bg-primary-500 text-white shadow-lg z-50">
        {/* Logo and App Name */}
        <div className="flex items-center">
          <Link to="/">
            <img src={logo} alt="Sabeel Logo" className="h-10 md:h-12 w-10 md:w-12 mx-2" />
          </Link>
          <Link to="/">
            <span className="text-xl md:text-2xl">{t("sabeel")}</span>
          </Link>
        </div>
        {/* Show Cart Icon with Total if there are items in the cart */}
        {cartTotal > 0 && (
          <div
            className="relative flex items-center bg-black bg-opacity-30 px-4 py-2 rounded cursor-pointer mt-2 md:mt-0"
            onClick={() => navigate("/cart")}
          >
            <FontAwesomeIcon icon={faShoppingCart} className="text-white text-lg" />
            <span className="ml-2">{cartTotal} {cartCurrency}</span>
          </div>
        )}

        {/* Mobile Menu */}
        <div className="flex flex-col items-center">

          <div className="block md:hidden">
            <button onClick={() => setShowDropdown(!showDropdown)}>
              <FontAwesomeIcon icon={faBars} className="text-white text-xl" />
            </button>
          </div>




          {/* Menu Items */}
          <div
            className={`md:flex ${showDropdown ? "block" : "hidden"
              } flex-col md:flex-row items-center content-center gap-3 mt-3 md:mt-0`}
          >
            <select
              value={i18n.language}
              onChange={onLanguageChange}
              className="flex items-center bg-black bg-opacity-30 py-1 rounded cursor-pointer mt-2 md:mt-0 z-50"
            >
              <option value="ar">{t("arabic")}</option>
              <option value="en">{t("english")}</option>
            </select>
            <CurrencySelect />



            {/* Authentication Buttons */}
            {isAuthenticated ? (
              <div
                className="flex items-center bg-black bg-opacity-30 px-4 py-2 rounded cursor-pointer mt-2 md:mt-0"
                onClick={handleLogout}
              >
                <FontAwesomeIcon icon={faSignOutAlt} className="mx-1" />
                <span>{t("logout")}</span>
              </div>
            ) : (
              <div
                className="flex items-center bg-black bg-opacity-30 px-4 py-2 rounded cursor-pointer mt-2 md:mt-0"
                onClick={handleLoginClick}
              >
                <FontAwesomeIcon icon={faUser} className="text-white mx-1" />
                <span>{t("login")}</span>
              </div>
            )}
          </div>
        </div>
      </nav>

      {/* Spacer to prevent content from being hidden behind the fixed navbar */}
      <div className="h-16 md:h-20"></div>

      {/* Render Login Modal */}
      <LoginModal showModal={showLoginModal} setShowModal={setShowLoginModal} handleLoginSuccess={handleLoginSuccess} />
    </>
  );
};

export default Navbar;
