import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import cartIcon from "../assets/images/cart.svg";
import { getCart, getCartTotal } from "../api/cart";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft,faArrowRight} from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import eventBus from "../utils/EventBus"; // Import EventBus


const CartOverview = () => {
  
    const { t } = useTranslation(); 
  const navigate = useNavigate();
  const [cartItems, setCartItems] = useState([]);
  const [total, setTotal] = useState(0);
   const selectedCountryCode = useSelector((state) => state.country.countryCode);
    const isArabic = useSelector((state) => state.language.language) === "ar";

  // Fetch cart items and total

    const fetchCartData = async () => {
      try {
        const cartData = await getCart(selectedCountryCode);
        const cartTotal = await getCartTotal(selectedCountryCode);
        setCartItems(cartData.data.data || []);
        setTotal(cartTotal.data.data);
      } catch (error) {
        console.error("Error fetching cart data:", error);
      }
    };


  useEffect(() => {
    fetchCartData(); // Initial fetch

    // Listen for "cartUpdated" event and refetch data
    const handleCartUpdate = () => {
      fetchCartData();
    };

    eventBus.addEventListener("cartUpdated", handleCartUpdate);

    return () => {
      eventBus.removeEventListener("cartUpdated", handleCartUpdate);
    };
  }, [selectedCountryCode]);
  // Hide component if the cart is empty
  if (cartItems.length === 0) return null;
  return (
    <div className="bg-gray-30 p-2 rounded-3xl shadow-md flex items-center justify-between gap-10 w-full">
      {/* Left Section - Cart Icon and Item Count */}
      <div className="flex items-center gap-4">
        <div onClick={() => navigate("/cart")} className="cursor-pointer w-14 h-14 flex items-center justify-center bg-white rounded-full shadow-md border border-gray-300">
          <img src={cartIcon} alt="Cart" className="w-6 h-6" />
        </div>
        <div>
            <div className="flex">
            <p className="text-white"> {t("cartProductsNumber")}</p>
            <p className="text-lg text-white">{cartItems.length}</p>
            </div>
         
        
        <p className="text-lg text-white">{total.total_amount} {total.currency}</p>
  
        
        </div>
      </div>

      {/* Center - Total Price */}
     

      {/* Right Section - Complete Payment Button */}
      <button
        onClick={() => navigate("/paymentV2")}
        className="bg-primary-500 text-white px-6 py-3 rounded-3xl  shadow-lg hover:bg-primary-600 transition-all flex justify-center items-center gap-2"
      >
        <span> {t("completeThePayment")}</span > {isArabic ?(<FontAwesomeIcon icon={faArrowLeft} />):(<FontAwesomeIcon icon={faArrowRight} />)} 
      </button>
    </div>
  );
};

export default CartOverview;
