import React, { useState, useEffect, useRef } from "react";
import PhoneInput from "react-phone-input-2";
import { useTranslation } from "react-i18next";
import "react-phone-input-2/lib/style.css";
import ar from "react-phone-input-2/lang/ar.json";
import { userLogin, validationCode } from "../../api/auth";
import { resendCode } from "../../api/customers";
import { useDispatch } from "react-redux";
import { login } from "../../redux/authSlice";
import logo from "../../assets/images/logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import { useSelector } from "react-redux";

const LoginModal = ({ showModal, setShowModal,handleLoginSuccess  }) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [phone, setPhone] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const [step, setStep] = useState(1); // Step 1 for phone input, Step 2 for code input
  const phoneInputRef = useRef(null);
  const auth = useSelector(state => state.auth);

  // Apply focus on phone input when the component is rendered
  useEffect(() => {
    const phoneInputField = document.getElementById("phoneInputField");
    if (phoneInputField) {
      phoneInputField.focus(); // Automatically focus the input
    }
  }, []);

  const handlePhoneChange = (value, data) => {
    const countryCode = data.dialCode;
    const phoneNumber = value.startsWith(`${countryCode}`)
      ? value.slice(`${countryCode}`.length)
      : value;
    setPhoneNumber(phoneNumber.trim());
    setCountryCode(countryCode);
  };

  const handleNextClick = async () => {
    try {
      const payload = {
        phone: phoneNumber,
        country_code: countryCode,
      };
      const response = await userLogin(payload);
      setStep(2); // Go to the verification step
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };

  const handleCodeChange = (e) => {
    const value = e.target.value.replace(/\D/g, ""); // Allow only numeric characters
    if (value.length <= 4) {
      setVerificationCode(value);
    }
    if (value.length === 4) {
      handleCodeSubmit(value); // Automatically submit when 4 digits are entered
    }
  };

  const handleCodeSubmit_ = async (code) => {
    try {
      const payload = {
        phone: phoneNumber,
        country_code: countryCode,
        code: code,
      };
      const response = await validationCode(payload);
      dispatch(
        login({
          token: response.data.data.token,
          user: response.data.data.user,
        })
      );
      setVerificationCode("");


  // Snap Pixel (Snapchat)
  if (window.snaptr) {
    window.snaptr('track', 'LOGIN', {
      user_phone_number: phoneNumber, 
      
    });
  } else {
    console.warn('Snap Pixel (snaptr) is not loaded');
  }
 // GTM Pixel
  if (window.dataLayer) {
    // Push the login event to GTM
    window.dataLayer.push({
      event: "LOGIN", // Custom event name
      user_phone_number: phoneNumber, // Replace with the actual phone number
    });
  } else {
    console.warn("GTM dataLayer is not loaded");
  }

  // TikTok Pixel
  if (window.ttq) {
    window.ttq.track('Login', {
      user_phone_number: phoneNumber, // Optional: send user phone number
    });
  } else {
    console.warn('TikTok Pixel (ttq) is not loaded');
  }
      handleLoginSuccess(); // Close the modal after login
      setStep(1);
      
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };

  const handleCodeSubmit = async (code) => {
    try {
      const fullPhoneNumber = `${countryCode}${phoneNumber}`; // Combine country code and phone number
  
      const payload = {
        phone: fullPhoneNumber, // Use the combined phone number
        code: code,
      };
  
      const response = await validationCode(payload);
      dispatch(
        login({
          token: response.data.data.token,
          user: response.data.data.user,
        })
      );
      setVerificationCode("");
  
      // Snap Pixel (Snapchat)
      if (window.snaptr) {
        window.snaptr('track', 'LOGIN', {
          user_phone_number: fullPhoneNumber, 
        });
      } else {
        console.warn('Snap Pixel (snaptr) is not loaded');
      }
  
      // GTM Pixel
      if (window.dataLayer) {
        window.dataLayer.push({
          event: "LOGIN",
          user_phone_number: fullPhoneNumber, 
        });
      } else {
        console.warn("GTM dataLayer is not loaded");
      }
  
      // TikTok Pixel
      if (window.ttq) {
        window.ttq.track('Login', {
          user_phone_number: fullPhoneNumber, 
        });
      } else {
        console.warn('TikTok Pixel (ttq) is not loaded');
      }
  
      handleLoginSuccess();
      setStep(1);
      
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };
  

  const handleResendCode = async () => {
    try {
      const payload = {
        phone: phoneNumber,
        country_code: countryCode,
      };
      await resendCode(payload);
      // Optionally, show a message indicating the code was resent
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };

  const handleClose = () => {
    
    setShowModal(false);
    setStep(1);
  };

  return (
    <>
      {showModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="relative bg-white rounded-lg w-[90%] sm:w-[90%] md:max-w-md p-6">
            <div className="absolute -top-10 left-1/2 transform -translate-x-1/2">
              <img src={logo} alt="Logo" className="h-16 w-16 md:h-20 md:w-20" />
            </div>
            <FontAwesomeIcon
              icon={faTimes}
              className="absolute top-4 right-4 text-gray-500 cursor-pointer text-2xl"
              onClick={handleClose}
              aria-label="Close modal"
            />
            {step === 1 && (
              <div className="mt-10 w-full" style={{ direction: "ltr" }}>
                <label htmlFor="phone" className="block mb-2 text-sm font-medium text-gray-700">
                  {t("phoneNumber")}
                </label>
                <div className="w-full">
                  <PhoneInput
                    id="phoneInputField"
                    country={"sa"}
                    value={phone}
                    onChange={handlePhoneChange}
                    enableSearch={true}
                    inputClass="w-full px-4 py-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary-500"
                    buttonClass="flag-dropdown"
                    localization={i18n.language === "ar" ? ar : undefined}
                    inputProps={{
                      id: "phoneInputField", // Adding an id to reference later
                      inputMode: "numeric",  // Ensure numeric keyboard
                      autoFocus: true,       // Set focus on input
                    }}
                   
                  />
                </div>
                <button
                  className="w-full bg-primary-500 text-white py-2 mt-6 rounded-md"
                  onClick={handleNextClick}
                >
                  {t("next")}
                </button>
              </div>
            )}
            {step === 2 && (
              <>
                <div className="mt-10 w-full">
                 
                  <input
                    id="codeInput"
                    type="text"
                    value={verificationCode}
                    maxLength={4}
                    placeholder="_ _ _ _"
                    inputMode="numeric" // Open number pad on mobile
                    pattern="[0-9]*" // Only allow numbers
                    autoComplete="one-time-code" // Auto-fill from SMS
                    className="w-full text-center px-4 py-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary-500"
                    onChange={handleCodeChange}
                    autoFocus
                  />
                </div>
                <div
                  className="text-center mt-4 text-primary-500 cursor-pointer font-bold"
                  onClick={handleResendCode}
                >
                  {t("resendActivationCode")}
                </div>
              </>
            )}
          </div>
        </div>
      )}
      <ToastContainer />
    </>
  );
};

export default LoginModal;
