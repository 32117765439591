import React from 'react';
import kaaba from "../assets/images/kaaba.png";
import elmadina from "../assets/images/el-madina.png";
import { useTranslation } from "react-i18next";

const CategorySelector = ({ handleCategoryClick, selectedCategory }) => {
  const { t } = useTranslation();
  return (
    <div className="flex gap-4 justify-center">
      {/* Mecca */}
      <div
        onClick={() => handleCategoryClick("mecca")}
        className={`flex items-center px-4 py-2 rounded-lg cursor-pointer border shadow-custom 
          ${selectedCategory === "mecca" ? "bg-red-500 text-white" : "bg-white border-gray-300"} 
          text-sm sm:text-base lg:text-lg`}
      >
        <img src={kaaba} alt="Kaaba Icon" className="w-6 h-6 mx-2" />
        {t("makkaMosques")}
      </div>

      {/* Pilgrims */}
      <div
        onClick={() => handleCategoryClick("pilgrims")}
        className={`px-4 py-2 rounded-lg cursor-pointer border shadow-custom 
          ${selectedCategory === "pilgrims" ? "bg-red-500 text-white" : "bg-white border-gray-300"} 
          text-sm sm:text-base lg:text-lg`}
      >
        {t("pilgrimsMosques")}
      </div>

      {/* Medina */}
      <div
        onClick={() => handleCategoryClick("medina")}
        className={`flex items-center px-4 py-2 rounded-lg cursor-pointer border shadow-custom 
          ${selectedCategory === "medina" ? "bg-red-500 text-white" : "bg-white border-gray-300"} 
          text-sm sm:text-base lg:text-lg`}
      >
        <img src={elmadina} alt="Medina Icon" className="w-6 h-6 mx-2" />
        {t("medinaMosques")}
      </div>
    </div>
  );
};

export default CategorySelector;
