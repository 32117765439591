import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus, faTrash } from "@fortawesome/free-solid-svg-icons";
import mosqueIcon from "../assets/images/mosqueIcon.svg";
import { getCart, EditProductInCart, DeleteProductFromCart, getCartTotal } from "../api/cart";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { trackPageVisit } from "../utils/analytics";  // Import tracking function

const Cart = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [cartItems, setCartItems] = useState([]);
  const [cartTotal, setCartTotal] = useState(null);
  const selectedCountryCode = useSelector((state) => state.country.countryCode);

  // Fetch cart data
  useEffect(() => {

    trackPageVisit("Cart");


    const fetchCart = async () => {
      try {
        const cartData = await getCart(selectedCountryCode);
        setCartItems(cartData.data.data);
      } catch (error) {
        console.error("Error fetching cart:", error);
      }
    };

    fetchCart();
  }, [selectedCountryCode]);

  // Fetch total when cartItems change
  useEffect(() => {
    const fetchCartTotal = async () => {
      try {
        const total = await getCartTotal(selectedCountryCode);
        //console.log(total)
        setCartTotal(total.data.data); // Assuming API returns { total: amount }
      } catch (error) {
        console.error("Error fetching cart total:", error);
      }
    };

    fetchCartTotal();
  }, [cartItems, selectedCountryCode]);

  // Function to Increase Quantity
  const increaseQuantity = async (id, currentQty) => {
    try {
      const newQty = currentQty + 1;
      await EditProductInCart({ qty: newQty }, id);

      setCartItems((prev) =>
        prev.map((item) => (item.id === id ? { ...item, qty: newQty } : item))
      );
    } catch (error) {
      console.error("Error updating quantity:", error);
    }
  };

  // Function to Decrease Quantity or Remove Item
  const decreaseQuantity = async (id, currentQty) => {
    try {
      if (currentQty > 1) {
        const newQty = currentQty - 1;
        await EditProductInCart({ qty: newQty }, id);

        setCartItems((prev) =>
          prev.map((item) => (item.id === id ? { ...item, qty: newQty } : item))
        );
      } else {
        await DeleteProductFromCart(id);
        setCartItems((prev) => prev.filter((item) => item.id !== id));
      }
    } catch (error) {
      console.error("Error updating cart:", error);
    }
  };

  // Function to Delete Product from Cart
  const deleteProduct = async (id) => {
    try {
      await DeleteProductFromCart(id);
      setCartItems((prev) => prev.filter((item) => item.id !== id));
    } catch (error) {
      console.error("Error deleting product:", error);
    }
  };

  return (
    <div className="bg-gray-10 flex flex-col items-center min-h-screen pb-10">
      <div className="w-full bg-white rounded mb-5 py-5 flex px-4 shadow-custom">
        <div className="w-1/2"></div>
        <div className="w-1/2 flex justify-between items-center">
          <p className="font-bold text-xl sm:text-2xl flex-1">{t("cart")}</p>
          {/* <p className="font-bold text-xl sm:text-2xl text-error-500">
            {t("deleteTheCart")}
          </p> */}
        </div>
      </div>

      {/* Cart Items List */}
      <div className="w-full max-w-3xl mt-5">
        {cartItems.map((item) => (
          <div
            key={item.id}
            className="bg-white rounded-lg p-4 shadow-md mb-4 mx-5"
          >
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-4">
                <img
                  src={item.product.photo}
                  alt={item.product.name}
                  className="w-28 h-auto rounded-md"
                />
                <p className="text-lg font-semibold">{item.product.name}</p>
              </div>

              <div className="text-right">
                <p className="text-lg font-semibold text-gray-700">
                  {item.product.price} {item.product.currency}
                </p>
                <div className="flex items-center justify-end mt-2 bg-[#DEDDFC99] p-1 rounded-xl">
                  <button
                    onClick={() => increaseQuantity(item.id, item.qty)}
                    className="px-1 text-white bg-primary-500 rounded-full"
                  >
                    <FontAwesomeIcon icon={faPlus} />
                  </button>
                  <span className="mx-3 text-lg font-semibold">{item.qty}</span>
                  {item.qty > 1 ? (
                    <button
                      onClick={() => decreaseQuantity(item.id, item.qty)}
                      className="px-1 text-white bg-primary-500 rounded-full"
                    >
                      <FontAwesomeIcon icon={faMinus} />
                    </button>
                  ) : (
                    <button
                      onClick={() => deleteProduct(item.id)}
                      className="p-1 text-error-500 rounded-full"
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </button>
                  )}

                </div>
              </div>
            </div>

            <hr className="my-4 border-gray-300" />

            <div className="flex items-center justify-between">
                {/* Mosque Icon and Place Name */}
                <div className="flex items-center gap-2">
                <img src={mosqueIcon} alt="Mosque Icon" className="w-8 h-8" />
                <p className="text-sm">{item?.place_name}</p>
              </div>
              {/* Delete Button on the Left */}
              <button
                onClick={() => deleteProduct(item.id)}
                className="p-2 text-error-500 rounded-full hover:bg-red-100"
              >
                <FontAwesomeIcon icon={faTrash} />
              </button>

            
            </div>
          </div>
        ))}
      </div>

      {/* Checkout Button */}
      <button onClick={() => navigate("/paymentV2")} className="mt-6 bg-primary-500 text-white text-lg font-semibold px-6 py-3 rounded-lg shadow-lg">
        {t("completeTheOrder")} ({cartTotal?.total_amount} {cartTotal?.currency})
      </button>
    </div>
  );
};

export default Cart;
