import React from "react";
import { useNavigate } from "react-router-dom";
import cartIcon from "../assets/images/cart.svg";

const CartNavigationButton = () => {
  const navigate = useNavigate();

  return (
    <div className="flex items-center  w-full">
      {/* Left Line */}
      <div className="flex-1 h-[1px] bg-[#00000026] mx-10"></div>

      {/* Cart Button */}
      <button
        onClick={() => navigate("/cart")}
        className="w-14 h-14 flex items-center justify-center bg-white rounded-full shadow-md border border-gray-300 hover:shadow-lg transition-all"
      >
        <img src={cartIcon} alt="Cart" className="w-6 h-6" />
      </button>

      {/* Right Line */}
      <div className="flex-1 h-[1px] bg-[#00000026] mx-10"></div>
    </div>
  );
};

export default CartNavigationButton;
