import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import ProductCard from "../components/ProductCard";
import { getCategories } from "../api/category";
import { useSelector } from "react-redux";
import CartOverview from "../components/CartOverview";
import { trackPageVisit } from "../utils/analytics";  // Import tracking function

const MosquesProducts = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const selectedMosque = location.state?.selectedMosque;
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const isArabic = useSelector((state) => state.language.language) === "ar";
  const selectedCountryCode = useSelector((state) => state.country.countryCode);
  const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

  useEffect(() => {
    trackPageVisit("MosquesProducts");

    //console.log(selectedMosque);
    // Fetch categories and products when component mounts
    const fetchCategories = async () => {
      try {
        const data = await getCategories(selectedCountryCode);
        //console.log(data.data.data);
        setCategories(data.data.data);

        if (data.data.data.length > 0) {
          setSelectedCategory(null); // Set the first category as default
          setProducts(data.data.data); // Use fetched categories as products
        }
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();
  }, [selectedCountryCode]);

  return (
    <div className="bg-gray-10 flex flex-col items-center min-h-screen pb-10">
      <div className="w-full bg-white rounded mb-5 text-center py-5">
        <p className="font-bold text-xl sm:text-2xl"> {t("sabeelProducts")}</p>
      </div>

      {selectedMosque && (
        <div className="flex items-center bg-white rounded-lg p-4 shadow-md w-full max-w-3xl my-4">
          {/* Display the image if available */}
          {selectedMosque?.image && (
            <img
              src={selectedMosque.image}
              alt={selectedMosque.name}
              className="w-16 h-16 rounded-lg object-cover"
            />
          )}

          {/* Display the mosque name */}
          <p className="text-xl font-bold mr-4">{selectedMosque?.name}</p>
        </div>
      )}

      {/* Category Buttons */}
      <div className="flex flex-wrap justify-center p-2 rounded-3xl gap-2">
        <button
          key="all"
          onClick={() => setSelectedCategory(null)}
          className={`px-4 py-2 rounded-xl transition-all shadow-xl ${
            selectedCategory === null
              ? "bg-[#D03C24] text-white"
              : "bg-[#0804FC0D] text-primary-500"
          }`}
        >
          {t("allProducts")}
        </button>
        {categories?.map((category) => (
          <button
            key={category.id}
            onClick={() => {
              setSelectedCategory(category.id);
              setProducts(categories.filter((cat) => cat.id === category.id));
            }}
            className={`px-4 py-2 rounded-xl transition-all shadow-xl ${
              selectedCategory === category.id
                ? "bg-[#D03C24] text-white"
                : "bg-[#0804FC0D] text-primary-500 "
            }`}
          >
            {t(category.name)}
          </button>
        ))}
      </div>

      {/* Product List */}
      {loading ? (
        <p className="mt-6 text-lg">Loading products...</p>
      ) : (
        <div className="mt-6 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
          {products?.length > 0 ? (
            products.map((product) => (
              <ProductCard
                key={product.id}
                product={product}
                isArabic={isArabic}
                selectedMosque={selectedMosque}
              />
            ))
          ) : (
            <p className="col-span-full text-gray-500">لا توجد منتجات متاحة</p>
          )}
        </div>
      )}

      <div className="my-10">
        <CartOverview />
      </div>
    </div>
  );
};

export default MosquesProducts;
