import { api_url } from "../config/config";
import { authorizedHttpJson } from "../config/http";




export async function getUserPlaces() {
  return await authorizedHttpJson.get(`${api_url}/v2/user/places`);
}

  export async function AddUsePlace(data) {
    return await authorizedHttpJson.post(`${api_url}/v2/user/place`,data);
  }

  export async function DeleteUserPlace(id) {
    return await authorizedHttpJson.delete(`${api_url}/v2/user/place/${id}`);
  }