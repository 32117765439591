import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import add_to_cart from "../assets/images/add-to-cart.svg";
import { AddProductToCart } from "../api/cart";
import eventBus from "../utils/EventBus";
import { useSelector } from "react-redux";
import LoginModal from "../components/Modals/LoginModal";
import { trackEvent } from "../utils/analytics"; // Import GTM tracking function

const ProductCard = ({ product, isArabic, selectedMosque }) => {
  const { t } = useTranslation();
  const isLoggedIn = useSelector((state) => state.auth.isAuthenticated);
  const [showLoginModal, setShowLoginModal] = useState(false);

  // Function to handle adding the product to the cart
  const addToCart = async () => {
    if (!isLoggedIn) {
      setShowLoginModal(true); // Show the login modal
      return; // Stop further execution
    }

    if (!selectedMosque) {
      alert("Please select a place first."); // Prevent errors if no mosque is selected
      return;
    }

    const cartData = {
      product_id: product.id,
      place_name: selectedMosque.name,
      latitude: selectedMosque.geometry.location.lat,
      longitude: selectedMosque.geometry.location.lng,
      qty: product.quantity || 1, // Default to 1, modify if needed
    };

    try {
      const response = await AddProductToCart(cartData);
      // Emit event to notify CartOverview to refetch data
      eventBus.dispatchEvent(new Event("cartUpdated"));

      // ✅ Send "Add to Cart" event to GTM
      trackEvent("add_to_cart", {
        product_name: product.name,
        product_id: product.id,
        price: product.price,
        quantity: product.quantity || 1,
        location: selectedMosque.name,
      });

      console.log("Added to cart:", response);
    } catch (error) {
      console.error("Error adding to cart:", error);
    }
  };

  const handleLoginSuccess = () => {
    // Simulate logging in successfully
    setShowLoginModal(false);
  };

  return (
    <>
      <div className="bg-white p-4 rounded-xl shadow-md w-64 flex flex-col items-center">
        <img src={product?.photo} alt={product?.name} className="w-50 h-20  mb-2" />
        {product.name && <p className="font-bold text-lg mt-5">{product.name}</p>}
        {product.quantity && <p className="text-sm text-gray-500">{product.quantity}</p>}
        {product.price && (
          <p className="text-primary-500 font-semibold">
            {product.price} {product.currency}
          </p>
        )}

        <button
          onClick={addToCart}
          className="relative text-white px-4 py-2 rounded-3xl flex items-center mt-2"
          style={{
            background: `
      linear-gradient(94.24deg, #0804FC 0%, #050296 99.26%),
      linear-gradient(95.02deg, rgba(255, 255, 255, 0.2) 0%, rgba(0, 0, 0, 0.2) 100.32%),
      linear-gradient(95.02deg, rgba(255, 255, 255, 0.2) 0%, rgba(0, 0, 0, 0.2) 100.32%),
      linear-gradient(90deg, rgba(255, 255, 255, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%)`,
          }}
        >
          {isArabic ? (
            <>
              <span className="mr-10">{t("addToCart")}</span>
              <img src={add_to_cart} alt="add_to_cart" className="absolute top-0 right-0  w-[50px]" />
            </>
          ) : (
            <>
              <img src={add_to_cart} alt="add_to_cart" className="w-[50px]  absolute top-0 left-0 " />
              <span className="ml-10">{t("addToCart")}</span>
            </>
          )}
        </button>

        {showLoginModal && (
          <LoginModal
            showModal={showLoginModal}
            setShowModal={setShowLoginModal}
            handleLoginSuccess={handleLoginSuccess}
          />
        )}
      </div>
    </>
  );
};

export default ProductCard;
