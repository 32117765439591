import React, { useState } from 'react';
import contact from '../assets/images/contact.png';
import { useTranslation } from "react-i18next";
import { createNewSuggetion } from "../api/customers";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faApple, faGooglePlay} from '@fortawesome/free-brands-svg-icons';
import { faTimes } from "@fortawesome/free-solid-svg-icons";

const ContactUs = () => {
  const [selectedOption, setSelectedOption] = useState('suggestion'); // Default to 'suggestion'
  const [notes, setNotes] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false); // State to manage modal visibility
  const { t } = useTranslation(); 

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
    if (e.target.value === 'problem') {
      setIsModalOpen(true); // Open modal when 'problem' is selected
    }
  };

  const handleNotesChange = (e) => {
    setNotes(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (selectedOption === 'suggestion') {
      try {
        // Call the API function to create a new suggestion
        await createNewSuggetion({message:notes });
        toast.success(t("suggestionSubmitted")); // Notify the user of success
        setNotes(''); // Reset notes after submission
      } catch (error) {
        console.error("Error submitting suggestion:", error);
        toast.error(t("submissionError")); // Notify the user of error
      }
    }
  };

  const handleCloseModal = () => {
    setSelectedOption("suggestion")
    setIsModalOpen(false); // Close the modal
  };

  return (
    <div className='flex flex-col items-center pb-10'>
      <img 
        src={contact} 
        alt="service type header" 
        className="w-[85%]" 
      />
      <p className='font-bold text-primary-500 mt-10'> {t("contactUs")}</p>
      <p className='font-bold mt-2'> {t("yourOpinionMatters")}</p>

      <form onSubmit={handleSubmit} className='w-[90%] max-w-md mt-10'>
        <div className='mb-4'>
          <div className='flex space-x-4'>
          
            <label className='inline-flex items-center'>
              <input 
                type='radio' 
                value='suggestion' 
                checked={selectedOption === 'suggestion'} 
                onChange={handleOptionChange}
                className='form-radio text-primary-500'
              />
              <span className='mx-2'>{t("suggestion")}</span>
            </label>
            <label className='inline-flex items-center'>
              <input 
                type='radio' 
                value='problem' 
                checked={selectedOption === 'problem'} 
                onChange={handleOptionChange}
                className='form-radio text-primary-500'
              />
              <span className='mx-2'>{t("problem")}</span>
            </label>
          </div>
        </div>

        {selectedOption === 'suggestion' && (
          <div className='mb-4'>
            <textarea
              value={notes}
              onChange={handleNotesChange}
              className='w-full p-3 bg-gray-10 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary-500'
              rows='4'
              placeholder={t("yourNote")}
            />
          </div>
        )}

        <div className='flex justify-center'>
          <button 
            type='submit' 
            className='bg-primary-500 text-white font-bold py-2 px-10 rounded-lg hover:bg-primary-600'
          >
            {t("send")}
          </button>
        </div>
      </form>

      {/* Custom Modal for downloading the app */}
      {isModalOpen && (
         <div className="modal fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white p-4 rounded shadow-lg w-[90%] max-w-md relative">
          <FontAwesomeIcon
          icon={faTimes}
          className="absolute top-4 right-4 text-gray-500 cursor-pointer text-lg sm:text-2xl"
          onClick={handleCloseModal}
          aria-label="Close modal"
        />
            <h2 className="text-lg font-bold text-center text-primary-500 my-3">{t("downloadTheAppToSendProblem")}</h2>
            <div className="flex flex-col sm:flex-row justify-center items-center gap-8 my-5">
      <a
                href="https://apps.apple.com/us/app/sabeel-%D8%B3%D8%A8%D9%8A%D9%84/id1463403733?ls=1"
                target="_blank"
                rel="noopener noreferrer"
                className="bg-black text-white px-4 py-1 rounded flex gap-3 items-center"
              >
                <FontAwesomeIcon
                  icon={faApple}
                  className="text-lg text-white"
                  aria-label="Apple Store"
                />
                <div >
                  <p>{t("downloadFrom")}</p>
                  <p className="font-bold">{t("appStore")}</p>
                </div>
              </a>

              <a
                href="https://play.google.com/store/apps/details?id=com.dd.sabeel"
                target="_blank"
                rel="noopener noreferrer"
                className="bg-black text-white px-4 py-1 rounded flex gap-3 items-center"
              >
                <FontAwesomeIcon
                  icon={faGooglePlay}
                  className="text-lg text-white"
                  aria-label="Google Play Store"
                />
                <div >
                  <p>{t("downloadFrom")}</p>
                  <p className="font-bold">{t("googlePlay")}</p>
                </div>
              </a>
      </div>
           
          </div>
        </div>
      )}

      <ToastContainer /> {/* ToastContainer for displaying toasts */}
    </div>
  );
};

export default ContactUs;
