import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from "react-i18next";

const NewSelectedMosquesList = ({ selectedMosquesList, removeMosqueFromList, setShowOrderModal }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();
  // Toggle open/close state
  const toggleList = () => {
    setIsOpen(!isOpen);
  };



  return (
    <div className="bg-white  px-4 py-2 border border-black border-opacity-[20%] rounded-lg shadow-custom">
      {/* Header with mosque count */}
      <h3 onClick={toggleList} className="cursor-pointer font-bold">
        {t("selectedMosques")} ({selectedMosquesList.length})
      </h3>
      

      {/* Conditional rendering based on isOpen */}
      {isOpen && (
        <>
        <hr className="mt-2 border-t border-black/20" />
          {selectedMosquesList.length > 0 ? (
            <>
              <ul>
                {selectedMosquesList.map((mosque) => (
                 
                  <li key={mosque.id} className="flex justify-between items-center mt-3">
                    {mosque.name}
                    <button
                      onClick={() => removeMosqueFromList(mosque.id)}
                      className="text-red-500 hover:text-red-700"
                      aria-label="Remove mosque"
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </button>
                  </li>
                ))}
              </ul>
              
            </>
          ) : (
            <p> {t("noSelectedMosques")}</p>  // Display message when no mosques are selected
          )}
        </>
      )}
    </div>
  );
};

export default NewSelectedMosquesList;

