import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { checkPaymentStatus } from "../api/orderV2"; // Ensure this import path is correct
import { useDispatch } from "react-redux";
import { trackEvent } from "../utils/analytics"; // Import GTM tracking function

const PaymentResults = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const selectedCountryCode = useSelector((state) => state.country.countryCode);
  const [paymentStatusErrors, setPaymentStatusErrors] = useState("");
  const [orderId, setOrderId] = useState(""); 
  const getQueryParams = (param) => {
    return new URLSearchParams(location.search).get(param);
  };

  const verifyPaymentStatus = async (resourcePath) => {
    const data = {
      checkout_id: [resourcePath],
   
    };
       try {
          const response = await checkPaymentStatus(data, selectedCountryCode);
          //console.log(response);
          if (response.data.data.status == "success") {
            //console.log(response.data.status);
            setOrderId(response.data.data.order_id)
            setPaymentStatusErrors("");

            trackEvent("purchase", {
              order_id: orderId
            });


            navigate(`/paymentV2?status=success&orderId=${orderId}`); // Navigate to /payment with success query param
          } else if (response.data?.data?.status == "fail") {
            //console.log(response.data?.data?.status);
            setPaymentStatusErrors(response.data.data.description);
            const errorMessage = response.data.data.description;
            navigate(
              `/paymentV2?status=failed&error=${encodeURIComponent(errorMessage)}`
            ); // Navigate to /payment with failed query param
          }
        } catch (error) {
          console.log(error);
          setPaymentStatusErrors(error.response?.data?.message);
          const errorMessage = error.message;
          navigate(
            `/paymentV2?status=failed&error=${encodeURIComponent(errorMessage)}`
          ); // Navigate to /payment with failed query param in case of error
        }
      }; 
 

  useEffect(() => {
    const checkoutId = getQueryParams("checkoutId");

    if (checkoutId) {
      verifyPaymentStatus(checkoutId);
    }
  }, [location]);

  return <>{/* You can display the payment status or error message here */}</>;
};

export default PaymentResults;
