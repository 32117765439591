import { api_url } from "../config/config";
import { httpJson } from "../config/http";




export async function getCategories(countryCode) {
  return await httpJson.get(`${api_url}/products`,   {
    headers: {
      'country-code': countryCode 
    }
  })
}

export async function getCategoryProducts(id,countryCode) {
    return await httpJson.get(`${api_url}/products/${id}`,
      {
        headers: {
          'country-code': countryCode 
        }
      })
  
  }