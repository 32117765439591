import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import ProductCard from "../components/ProductCard";
import { getCategories } from "../api/category";
import { useSelector } from "react-redux";
import CartOverview from "../components/CartOverview";
import { trackPageVisit } from "../utils/analytics";  // Import tracking function

const ProphetMosqueProducts = () => {
  const { t } = useTranslation();
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("water");
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const isArabic = useSelector((state) => state.language.language) === "ar";
  const selectedCountryCode = useSelector((state) => state.country.countryCode);

  useEffect(() => {
    trackPageVisit("ProphetMosqueProducts");

    const fetchCategories = async () => {
      try {
        const data = await getCategories(selectedCountryCode);
        //console.log(data.data.data);

        // Filter relevant categories
        const filteredCategories = data.data.data.filter(
          (cat) => cat.name === "مياة" || cat.name === "وجبة جافة" || cat.name === "وجبة ساخنة"
        );

        setCategories(filteredCategories);
        setLoading(false);
        setSelectedCategory("water"); // Default selection
      } catch (error) {
        console.error("Error fetching categories:", error);
        setLoading(false);
      }
    };

    fetchCategories();
  }, [selectedCountryCode]);

  useEffect(() => {
    if (categories.length > 0) {
      if (selectedCategory === "water") {
        const waterCategory = categories.find((cat) => cat.name === "مياة");
        setProducts(waterCategory ? [waterCategory] : []);
      } else {
        const mealCategories = categories.filter((cat) => cat.name === "وجبة جافة" || cat.name === "وجبة ساخنة");
        setProducts(mealCategories);
      }
    }
  }, [selectedCategory, categories]);

  // Define quantity options
  const waterQuantities = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100];
  const mealQuantity = 10; // Fixed minimum quantity for meals

  return (
    <div className="bg-gray-10 flex flex-col items-center min-h-screen pb-10">
      <div className="w-full bg-white rounded mb-5 text-center py-5">
        <p className="font-bold text-xl sm:text-2xl">{t("wateringAndFeedingProphet")}</p>
      </div>

      {/* Toggle Buttons */}
      <div className="flex justify-center bg-[#0804FC1A] p-2 rounded-3xl gap-2 min-w-[360px] sm:min-w-[450px]">
        <button
          onClick={() => setSelectedCategory("water")}
          className={`flex-1 px-4 py-2 rounded-3xl transition-all ${
            selectedCategory === "water" ? "bg-[#D03C24] text-white" : "bg-[#F5F5F5]"
          }`}
        >
          {t("wateringProphet")}
        </button>
        <button
          onClick={() => setSelectedCategory("food")}
          className={`flex-1 px-4 py-2 rounded-3xl transition-all ${
            selectedCategory === "food" ? "bg-[#D03C24] text-white" : "bg-[#F5F5F5]"
          }`}
        >
          {t("feedingProphet")}
        </button>
      </div>

      {/* Product List */}
      <div className="mt-6 grid grid-cols-2 gap-6">
        {loading ? (
          <p>Loading...</p>
        ) : products.length > 0 ? (
          products.map((product) =>
            selectedCategory === "water"
              ? waterQuantities.map((quantity) => (
                  <ProductCard
                    key={`${product.id}-${quantity}`}
                    product={{
                      ...product,
                      quantity, // Current quantity
                      price: product.price * quantity, // Update price based on quantity
                    }}
                    isArabic={isArabic}
                    selectedMosque={{
                      name: "Prophet Mosque",
                      geometry: { location: { lat: 21.4225, lng: 39.8262 } },
                    }}
                  />
                ))
              : // Meals: Fixed quantity of 10
                product && (
                  <ProductCard
                    key={product.id}
                    product={{
                      ...product,
                      quantity: mealQuantity,
                      price: product.price * mealQuantity, // Price for 10 meals
                    }}
                    isArabic={isArabic}
                    selectedMosque={{
                      name: "Prophet Mosque",
                      geometry: { location: { lat: 21.4225, lng: 39.8262 } },
                    }}
                  />
                )
          )
        ) : (
          <p className="col-span-full text-gray-500">لا توجد منتجات متاحة</p>
        )}
      </div>

      <div className="my-10">
        <CartOverview />
      </div>
    </div>
  );
};

export default ProphetMosqueProducts;
