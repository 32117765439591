import TagManager from 'react-gtm-module';

// Function to send a page visit event
export const trackPageVisit = (pageName) => {
  TagManager.dataLayer({
    dataLayer: {
      event: "service_page_view",
      page: pageName,
    },
  });
};

// Function to send events to GTM
export const trackEvent = (eventName, eventData) => {
    TagManager.dataLayer({
      dataLayer: {
        event: eventName,
        ...eventData,
      },
    });
  };

  export const trackPurchase = (order) => {
    if (!order) {
      console.warn("trackCheckout: Invalid order data");
      return;
    }
  
    trackEvent("purchase", {
      order_id: order.id,
      payment_method: order.payment_method,
      transaction_id: order.txn_id,
      transaction_number: order.transaction_number,
      price: order.order_items_sum_total,
      item_ids: order.item_ids || "", // Ensure it exists or fallback to empty string
      item_category: order.item_category || "", // Ensure it exists or fallback to empty string
      currency: order.currency_sign,
      number_items: order.order_items_quantity,
      items: order.cart,
    });  
  };

  export const trackGooglePlayClick = () => {
    trackEvent("google_play_click", {
      action: "click",
    });
  };

  export const trackAppStoreClick = () => {
    trackEvent("app_store_click", {
      action: "click",
    });
  };