import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons'; // Import the X icon
import { useTranslation } from "react-i18next";

const NotAvailableHouseModal = ({ onClose }) => {
    const { t } = useTranslation();
  return (
    <div className="modal fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white p-4 rounded shadow-lg w-[90%] max-w-md relative">
        <div className="flex justify-between items-center mb-4">
          <button
            className="text-gray-600 ml-2"
            onClick={onClose}
            aria-label="Close Modal"
          >
            <FontAwesomeIcon icon={faTimes} size="lg" />
          </button>
          <h2 className="text-lg font-semibold flex-grow text-center">{t("sorry")}</h2>
        </div>

        <p className="mb-4 text-center"> {t("deliveryNotAvailableInThisArea")}</p>
        <button
          className="bg-primary-500 text-white px-4 py-2 rounded w-[100%]"
          onClick={onClose}
        >
          {t("close")}
        </button>
      </div>
    </div>
  );
};

export default NotAvailableHouseModal;
