import React, { useState,useRef,useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import paymentheader from "../assets/images/payment-header.png";
import camera from "../assets/images/camera.png";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import BankSelect from "../components/BankSelect";
import PaymentMethod from "../components/PaymentMethod";
import RecipientModal from "../components/Modals/RecipientModal";
import {faCirclePlus,faCircleMinus,faTimes,faSpinner } from "@fortawesome/free-solid-svg-icons";
import {createOrder, updateOrderForBank, getHyperpayCheckoutId,payWithKnet,checkCoupon} from "../api/orders";
import {getProducts} from "../api/products";
import { useDispatch } from 'react-redux';
import { setOrderIds ,setOrderCoupon} from '../redux/orderSlice';
import { setPaymentData } from '../redux/paymentSlice';
import HyperpayPaymentModal from '../components/Modals/HyperpayPaymentModal';
import PaymentSuccess from '../components/Modals/PaymentSuccess';
import PaymentFail from '../components/Modals/PaymentFail';
import { getCoordinates } from "../utils/functions";
import { useLocation,useNavigate  } from "react-router-dom";
import LoginModal from "../components/Modals/LoginModal"; // Import the LoginModal
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';



const Payment = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const language = useSelector((state) => state.language.language);
  const isLoggedIn = useSelector((state) => state.auth.isAuthenticated);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showCheckoutModal, setShowCheckoutModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showFailureModal, setShowFailureModal] = useState(false);
  const [checkoutId, setCheckoutId] = useState(null);
  const orderDetails = useSelector((state) => state.order);
  const [selectedBankId, setSelectedBankId] = useState(null);
  const [selectedMethod, setSelectedMethod] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [agreeTerms, setAgreeTerms] = useState(false);
  const [products, setProducts] = useState([]);
  const currency= language === 'ar' ?products[0]?.currency :products[0]?.currency_en;
  const [counters, setCounters] = useState( orderDetails.selectedPlacesList.map(() => orderDetails.quantity));
  const [recipientDetails, setRecipientDetails] = useState(
    orderDetails.selectedPlacesList.map(() => ({
      name: "",
      phone: "",
      mosqueGender: "",
      note: "",
    })));
  const [uploadedPhoto, setUploadedPhoto] = useState(null);
  const [photoPreview, setPhotoPreview] = useState(null);
  const fileInputRef = useRef(null); 
  const selectedCountryCode = useSelector((state) => state.country.countryCode);
  const orderType = useSelector((state) => state.order.orderType);
  const calculateTotal = () => {
    const selectedProduct = orderDetails.product === "water" ? products.find(product => product.id === 525) : products.find(product => product.id === 524);
    const productPrice = selectedProduct ? selectedProduct.price : 0; // Get price from API data
    //console.log("selectedProduct....",selectedProduct)
    const shippingCost = selectedProduct ? parseFloat(selectedProduct.shipping) : 0; // Get shipping from API data
  
    const totalBeforeVAT = counters.reduce(
      (acc, qty) => acc + qty * productPrice,
      0
    );
    const vatAmount = selectedProduct ? (totalBeforeVAT * selectedProduct.tax) / 100 : 0; // Get tax from API data
    return {
      totalBeforeVAT,
      vatAmount,
      total: totalBeforeVAT + vatAmount + shippingCost,
      shippingCost
    };
  };
  const totalValues = calculateTotal();
  const [totalAfterCoupon, setTotalAfterCoupon] = useState(0);
  const [couponCode, setCouponCode] = useState(""); // Coupon code state
  const [couponError, setCouponError] = useState(""); // Error state for coupon code
  const [isCouponApplied, setIsCouponApplied] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [purchaseAttempted, setPurchaseAttempted] = useState(false);

  useEffect(() => {
    getProducts(selectedCountryCode)
      .then((response) => {
        setProducts(response.data.data);
       
        
      })
      .catch((error) => {
        console.error(error);
      });
  }, [selectedCountryCode]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search); // Parse the URL parameters
    const paymentStatus = queryParams.get("status"); // Get the 'status' parameter
    const error = queryParams.get("error"); // Get the 'error' parameter

    if (paymentStatus === "success") {
      setShowSuccessModal(true); // Show success modal
      setShowFailureModal(false);
    } else if (paymentStatus === "failed") {
      setShowFailureModal(true); // Show failure modal
      setShowSuccessModal(false);
      setErrorMessage(error);
    }
  }, [location.search]);

  const handleCloseModal = () => {
    const params = new URLSearchParams(location.search);
    params.delete('status'); // Remove the 'status' parameter
    params.delete('error')
    navigate({
      pathname: location.pathname,
      search: params.toString(),
    });
    setShowSuccessModal(false);
    setShowFailureModal(false);
  };

  const handleBankSelection = (bankId) => setSelectedBankId(bankId);
  const handleMethodSelect = (method) => setSelectedMethod(method);


  const handleCounterChange = (index, change) => {
    setCounters((prev) => {
      const newCounters = [...prev];
      const minQty = orderDetails.product === "water" ? 10 : 1;
      newCounters[index] = Math.max(newCounters[index] + change, minQty);
      return newCounters;
    });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
        // Set the uploaded photo file for the API
        setUploadedPhoto(file);
        // Create a preview URL
        const reader = new FileReader();
        reader.onloadend = () => setPhotoPreview(reader.result);
        reader.readAsDataURL(file);
    }
};
const handlePhotoRemove =()=>{
  setUploadedPhoto(null);
  setPhotoPreview(null);
  fileInputRef.current.value = "";
}

const handleApplyCoupon = async () => {
  if (!couponCode.trim()) {
    setCouponError(t("enterCouponCode")); // Set the error message if the field is empty
    return;
  }

  const couponData = {
    product_id: orderDetails.product === "water" ? 525 : 524,
    total: totalValues.total,
    code: couponCode,
    shipping: totalValues.shippingCost,
  };

  try {
    const response = await checkCoupon(couponData);

    if (response.status) {
      const { total, discount_value, value, type } = response.data.data;
      if(response.data.status){
        setTotalAfterCoupon(total); // Update total after coupon
        setIsCouponApplied(true); // Mark coupon as applied
        dispatch(setOrderCoupon(couponCode));
      }else{
        setCouponError(response.data.message || t("invalidCoupon"));
        setIsCouponApplied(false); // Reset coupon applied state
      }
    } else {
      setCouponError(response.data.message || t("invalidCoupon"));
      setIsCouponApplied(false); // Reset coupon applied state
    }
  } catch (error) {
    setIsCouponApplied(false);
    setCouponError(t("somethingWentWrong"));
  }
};

const handlePurchase = async () => {
      // Check if user is logged in
      if (!isLoggedIn) {
        setPurchaseAttempted(true);
        setShowLoginModal(true); // Show the login modal
        return; // Stop further execution
      }
        // Check if the policy option is agreed
  if (!agreeTerms) {
    // Show toast notification to check the policy option
    toast.error(t("pleaseCheckThePolicy")); 
    return; // Stop further execution
  }
  setLoading(true); // Start loader
  try {
    const orderData = {
      order_type: orderType,
      data: orderDetails.selectedPlacesList.map((place, index) => {
        try {
          const { lat, lng } = getCoordinates(place.geometry.location);
          return {
            latitude: lat,
            longitude: lng,
            recepient_phone: orderDetails.isMosqueSelected
              ? recipientDetails[index].phone
              : "", // Set phone based on condition
            order_subscription_date: orderDetails.firstOrderDate || "",
            gift_phone: orderDetails.isMosqueSelected
              ? ""
              : recipientDetails[index].phone, // Set gift_phone if not selected
            men: recipientDetails[index].mosqueGender === "men",
            qty: counters[index],
            order_subscription_count: orderDetails.subscriptionPeriod || 1,
            women: recipientDetails[index].mosqueGender === "women",
            order_type: "default",
            customer_note: recipientDetails[index].note,
            service_type: orderDetails.isMosqueSelected ? "mosque" : "home",
            gift_name: orderDetails.isMosqueSelected
              ? ""
              : recipientDetails[index].name, // Set gift_name if not selected
            mosque_name: place.name,
            place_tag: place.vicinity,
            place_name: place.name,
            address: place.name,
            prodcut_id: orderDetails.product === "water" ? 525 : 524,
            recepient_name: orderDetails.isMosqueSelected
              ? recipientDetails[index].name
              : "", // Set name based on condition
          };
        } catch (error) {
          return null; // Return null or handle the error as needed
        }
      }).filter(Boolean), // Filter out any null values from the map
      countryCode: selectedCountryCode,
      product_id: orderDetails.product === "water" ? 525 : 524,
      service_type: orderDetails.isMosqueSelected ? "mosque" : "home",
    };
  
    try {
      const response = await createOrder(orderData, selectedCountryCode); // Call API function
      
      const orderIds = response.data.data; // Access order IDs from the response
      dispatch(setOrderIds(orderIds));
     
  
      if (selectedMethod === "bank") {
        const bankPaymentData = {
          payment_method: "bank",
          bank_id: selectedBankId,
          receipt: uploadedPhoto,
          coupon: couponCode, // Replace with actual coupon if used
          order_ids: orderIds,
        };
        try {
          const paymentByBankResponse = await updateOrderForBank(bankPaymentData);
         
          if (paymentByBankResponse.data.status === true) {
            dispatch(setPaymentData({
              date: new Date().toLocaleDateString(),
              time: new Date().toLocaleTimeString(),
              total: totalValues,
              totalAfterCoupon:totalAfterCoupon,
              isCouponApplied:isCouponApplied,
              currency:currency,
              qrCodes:paymentByBankResponse.data.data,
              selectedMethod:selectedMethod
            }));
            setShowSuccessModal(true); // Show success modal
          }
        } catch (error) {
          //console.error("Payment error:", error);
        }
      } else if (selectedMethod === "knet") {
        // Prepare data for KNET payment method
        const knetCoupon = couponCode; // Replace with actual coupon if used
        // Call the payWithKnet function
        try {
          const knetResponse = await payWithKnet({coupon:knetCoupon, order_ids:orderIds}); // Call the payWithKnet function with coupon and orderIds
          if (knetResponse.status) {
            // Redirect user to the KNET payment URL
            const paymentUrl = knetResponse.data.data.url;
            window.location.href = paymentUrl; // Redirect to the KNET payment page
          } else {
            
          }
        } catch (error) {
          
        }
      } else {
        const hyperpayPaymentData = {
          payment_method: selectedMethod,
          coupon: couponCode,
          order_ids: orderIds,
        };
  
        const hyperpayResponse = await getHyperpayCheckoutId(hyperpayPaymentData,selectedCountryCode);
       
  
        const checkoutId = hyperpayResponse.data.data;
        setCheckoutId(checkoutId.id);
        dispatch(setPaymentData({
          date: new Date().toLocaleDateString(),
          time: new Date().toLocaleTimeString(), 
          total: totalValues,
          totalAfterCoupon:totalAfterCoupon,
          isCouponApplied:isCouponApplied,
          currency:currency,
          selectedMethod:selectedMethod
        }));
        setShowCheckoutModal(true);
  

      }
    } catch (error) {
      //console.error("Error creating order:", error);
    }
  } catch (error) {
    //console.error("Error during purchase process:", error);
  }finally {
    setLoading(false); // Stop loader
  }
};
const handleLoginSuccess = () => {
  // Simulate logging in successfully
  setShowLoginModal(false);
};

  // Effect to handle purchase attempt after login state changes
  useEffect(() => {
    if (isLoggedIn && purchaseAttempted) {
      setPurchaseAttempted(false); // Reset the flag
      handlePurchase(); // Trigger the purchase
    }
  }, [isLoggedIn, purchaseAttempted]);

  const PaymentSummary = () => (
   
    <div className="bg-primary-500 bg-opacity-20 p-4 rounded-lg">
      <div className="flex justify-between mb-2">
        <p>{t("totalBeforeVAT")}</p>
        <p>
          {totalValues.totalBeforeVAT} {currency}
        </p>
      </div>
      <div className="flex justify-between mb-2">
        <p>{t("valueAdded")}</p>
        <p>
          {totalValues.vatAmount} {currency}
        </p>
      </div>
      <div className="flex justify-between">
        <p>{t("shippingCost")}</p>
        <p>
          {totalValues.shippingCost} {currency}
        </p>
      </div>
      <hr className="my-3" />
      <div className="flex justify-between font-bold">
        <p>{t("total")}</p>
        <p>
        {/* Conditional rendering based on whether a coupon has been applied */}
        {isCouponApplied ? totalAfterCoupon.toFixed(2) : totalValues.total.toFixed(2)} 
        {" "}{currency}
        </p>
      </div>
    </div>
   
  );
  

  return (
    <div className="bg-gray-10 flex flex-col items-center min-h-screen pb-10">
      <div className="w-[90%] sm:w-[80%] bg-white rounded-lg shadow-md">
        <img
          src={paymentheader}
          alt="service type header"
          className="w-[100%] mx-auto mb-5"
        />
        <div className="text-center mb-5">
          <p className="font-bold text-xl sm:text-2xl">{t("payment")}</p>
        </div>
      </div>

      <div className="flex flex-col md:flex-row justify-between gap-8 w-[90%] sm:w-[80%] mt-10">
        <div className="w-full md:w-1/2 bg-white rounded-lg p-4">
          <div className="flex flex-col md:grid md:grid-cols-2 gap-4">
            <PaymentMethod
              selectedMethod={selectedMethod}
              handleSelect={handleMethodSelect}
            />
          </div>
          {selectedMethod === "bank" && (
            <>
              <BankSelect onBankSelect={handleBankSelection} />
              <div className="mt-5">
                <span className="font-bold"> {t("attachTransferPhoto")}</span>
                <div className="flex gap-20">
                  <div className="cursor-pointer border rounded-lg p-3 mt-3 flex items-center">
                    <img
                      src={camera}
                      alt="Attach Image"
                      className="w-10"
                      onClick={() =>
                        document.getElementById("file-input").click()
                      }
                    />
                    <input
                      type="file"
                      id="file-input"
                      accept="image/*"
                      style={{ display: "none" }}
                      ref={fileInputRef}
                      onChange={handleFileChange}
                    />
                  </div>
                  {photoPreview && (
                    <div className="relative">
                      <img
                        src={photoPreview}
                        alt="Uploaded"
                        className="mt-2 w-20 h-20"
                      />
                      <button
                        className="absolute top-0 right-0 bg-gray-30 text-white rounded-full px-1"
                        onClick={handlePhotoRemove}
                      >
                        <FontAwesomeIcon icon={faTimes} />
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </>
          )}

          <div className="flex items-center gap-2 mt-5">
            <input
              type="checkbox"
              id="terms"
              checked={agreeTerms}
              onChange={() => setAgreeTerms(!agreeTerms)}
            />
            <label htmlFor="terms" className="text-sm">
              {t("haveReviewed")}{" "}
              <Link to="/terms-and-conditions" className="text-primary-500">
                {t("privacyPolicy")}
              </Link>
            </label>
          </div>
        </div>

        <div className="w-full md:w-1/2 flex flex-col gap-4">
          <div className="flex gap-2">
            <input
              type="text"
              value={couponCode}
              onChange={(e) => {
                setCouponCode(e.target.value)
                if (couponError) setCouponError(""); // Clear error when user starts typing
              }}
              placeholder={t("enterDiscountCode")}
              className="flex-grow p-3 border rounded-lg"
            />
            <button 
            onClick={handleApplyCoupon}
            className="bg-primary-500 bg-opacity-[20%] text-primary-500 p-3 rounded-lg w-1/4">
              {t("submit")}
            </button>
          </div>
          {couponError && <p className="text-red-500 my-2">{couponError}</p>}
          <button
            className="bg-white shadow p-3 rounded-lg flex items-center gap-2 justify-center w-[100%] md:w-[40%]"
            onClick={() => setShowModal(true)}
          >
            <FontAwesomeIcon icon={faCirclePlus} size="lg" />
            <span>{t("receipientName")}</span>
          </button>

          {orderDetails.selectedPlacesList.map((place, index) => (
            <div
              key={index}
              className="bg-primary-500 bg-opacity-20 p-3 rounded-lg"
            >
              <p className="font-bold text-primary-500">{place.name}</p>
              <hr className="my-2 bg-black opacity-[20%]" />
              <p className="text-center mb-2 mt-4">
                {orderDetails.product === "water"
                  ? t("sabeelWater")
                  : t("sabeelCooler")}
              </p>
              <div className="flex items-center justify-center gap-5">
                <FontAwesomeIcon
                  icon={faCircleMinus}
                  onClick={() => handleCounterChange(index, -1)}
                  className="text-primary-500 cursor-pointer"
                />
                <p className="text-xl font-bold">{counters[index]}</p>
                <FontAwesomeIcon
                  icon={faCirclePlus}
                  onClick={() => handleCounterChange(index, 1)}
                  className="text-primary-500 cursor-pointer"
                />
              </div>
            </div>
          ))}

          <div className="mt-4">
            <p className="font-bold">{t("orderDetails")}</p>
          </div>
          <PaymentSummary />

          <button
            className="bg-primary-500 text-white p-4 rounded-lg w-full mt-4"
            onClick={handlePurchase} // Add this to call handlePurchase when clicked
            disabled={loading}
           
          >
             {loading ? (
        <div className="flex justify-center items-center">
        
        <FontAwesomeIcon icon={faSpinner} spin className="mx-2" />
        <span className="">{t("processingPurchase")}</span>
        
       
      </div>
  ) : (<>
            {t("purchase")} {" "}
            {isCouponApplied ? totalAfterCoupon.toFixed(2) : totalValues.total.toFixed(2) } 
           {" "} {currency}
           </>)}
          </button>
        </div>
      </div>

      {showModal && (
        <RecipientModal
          setShowModal={setShowModal}
          recipientDetails={recipientDetails}
          setRecipientDetails={setRecipientDetails}
        />
      )}

      {showCheckoutModal && (
             <HyperpayPaymentModal
             selectedMethod={selectedMethod}
             showCheckoutModal={showCheckoutModal}
             onClose={() => setShowCheckoutModal(false)}
             checkoutId={checkoutId}
           />
      )}
      {showSuccessModal && (
        <PaymentSuccess
        onClose={handleCloseModal}
         />
      )}

      {showFailureModal&&(
        <div>
             <PaymentFail onClose={handleCloseModal} errorMessage={errorMessage} />
        </div>
      )}

      {showLoginModal&&(
        <LoginModal showModal={showLoginModal} setShowModal={setShowLoginModal} handleLoginSuccess={handleLoginSuccess}/>
      )

      }
      <ToastContainer />
    </div>
  );
};

export default Payment;
