import React from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const PaymentFail = ({ onClose ,errorMessage}) => {
  const { t } = useTranslation();
  const closeModal = () => {
    onClose();
  };

  return (
    <>
      {/* Modal Background */}
      <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
        <div className="bg-white rounded-lg shadow-lg text-center w-[90%] sm:w-[70%] md:w-[50%] lg:w-[40%] relative p-6">
          {/* Close button as X icon */}
          <FontAwesomeIcon
            icon={faTimes}
            className="absolute top-4 right-4 text-gray-500 cursor-pointer text-2xl"
            onClick={closeModal}
            aria-label="Close modal"
          />

          {/* Modal Content */}
          <div className="flex flex-col items-center">
            {/* Failure Icon or Image */}
            <div className="mb-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                className="w-16 h-16 text-red-500"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M12 9v2m0 4h.01M19.428 15.5l-5.5-9.5M4.572 15.5l5.5-9.5M9.5 15.5h5m-6.5 2.5v.01M19.5 15.5v.01"
                />
              </svg>
            </div>
            <h2 className="text-xl font-semibold text-red-500 mb-4">
              {t('paymentFailedTitle')}
            </h2>
            <p className="text-gray-600 mb-6">
              
              {errorMessage}
            </p>

            {/* Action Button */}
            <button
              onClick={closeModal}
              className="px-6 py-2 bg-red-500 text-white font-semibold rounded-lg hover:bg-red-600"
            >
              {t('tryAgain')}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentFail;
