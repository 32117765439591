import React, { useState, useEffect } from "react";
import {
  GoogleMap,
  LoadScript,
  Marker,
  OverlayView,
} from "@react-google-maps/api";
import meccaData from "../jsons/mecca.json";
import pilgrimsData from "../jsons/umrah.json";
import medinaData from "../jsons/madina.json";
import polygonData from "../jsons/polygonData"; // Importing the polygon data
import NewSelectedMosquesList from "../components/NewSelectedMosquesList";
import MosqueModal from "../components/Modals/MosqueModal";
import OrderModal from "../components/Modals/OrderModal";
import NotAvailabePlace from "../components/Modals/NotAvailabePlace";
import CategorySelector from "../components/CategorySelector";
import SearchInput from "../components/SearchInput";
import kaaba from "../assets/images/kaabaImg.png";
import blue_marker from "../assets/images/blue-marker.png";
import mosque_marker from "../assets/images/mosque-marker.png";
import * as turf from "@turf/turf";
import { getCoordinates } from "../utils/functions";
import { useTranslation } from "react-i18next";
import Navbar from "../components/Navbar";
import { useNavigate } from "react-router-dom";
import { getUserPlaces, AddUsePlace, DeleteUserPlace } from "../api/userPlaces";
import CartOverview from "../components/CartOverview";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { trackPageVisit } from "../utils/analytics";  // Import tracking function

const MosquesProductsMap = ({ onLanguageChange }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [selectedMosque, setSelectedMosque] = useState(null);
  const [mosques, setMosques] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [center, setCenter] = useState({ lat: 25.276987, lng: 55.296249 });
  const [showModal, setShowModal] = useState(false);
  const [showOrderModal, setShowOrderModal] = useState(false);
  const [showNotAvailabePlaceModal, setShowNotAvailabePlaceModal] =
    useState(false);
  const [selectedMosquesList, setSelectedMosquesList] = useState([]);
  const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
  const kaabaPosition = { lat: 21.4225, lng: 39.8262 }; // Latitude and longitude of the Kaaba
  const [loadError, setLoadError] = useState(false);
  const [defaultIconSize, setDefaultIconSize] = useState(null);
  const [selectedIconSize, setSelectedIconSize] = useState(null);

  const mapContainerStyle = {
    width: "100%",
    height: "93vh",
  };

  const handleMapLoad = (map) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const userLocation = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          setCenter(userLocation);
        },
        () => {
          loadDefaultMosques();
        }
      );
    } else {
      loadDefaultMosques();
    }
    map.setOptions({ mapId: "1fccaf67bfe984c1" });

    map.addListener("click", (event) => {
      if (event.placeId) {
        event.stop();
        const service = new window.google.maps.places.PlacesService(map);
        service.getDetails({ placeId: event.placeId }, (place, status) => {
          if (status === window.google.maps.places.PlacesServiceStatus.OK) {
            handleMarkerClick(place);
          }
        });
      }
    });
  };

  const loadDefaultMosques = () => {
    let selectedMosques = [];
    if (selectedCategory === "mecca") {
      selectedMosques = meccaData.results;
    } else if (selectedCategory === "pilgrims") {
      selectedMosques = pilgrimsData.results;
    } else if (selectedCategory === "medina") {
      selectedMosques = medinaData.results;
    } else {
      selectedMosques = [
        ...meccaData.results,
        ...medinaData.results,
        ...pilgrimsData.results,
      ];
    }

    setMosques(selectedMosques);

    if (selectedMosques.length > 0) {
      const firstMosqueLocation = selectedMosques[0].geometry.location;
      setCenter({
        lat: firstMosqueLocation.lat,
        lng: firstMosqueLocation.lng,
      });
    }
  };

  useEffect(() => {
    trackPageVisit("MosquesProductsMap");

    const handleOnline = () => setLoadError(false);
    const handleOffline = () => setLoadError(true);

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);
  useEffect(() => {
    if (selectedCategory) {
      loadDefaultMosques();
    }
  }, [selectedCategory]);

  //get selected places
  const fetchUserPlaces = async () => {
    try {
      const response = await getUserPlaces();
      //console.log(response.data.data);
      setSelectedMosquesList(response.data.data);
    } catch (error) {
      console.error("Error fetching user places:", error);
    }
  };
  useEffect(() => {
    fetchUserPlaces();
  }, []);

  useEffect(() => {
    // Check if Google Maps is available
    if (typeof window !== "undefined" && window.google) {
      setDefaultIconSize({
        url: mosque_marker,
        scaledSize: new window.google.maps.Size(30, 30),
        origin: new window.google.maps.Point(0, 0),
        anchor: new window.google.maps.Point(15, 30),
      });
      setSelectedIconSize({
        url: blue_marker,
        scaledSize: new window.google.maps.Size(33, 33),
        origin: new window.google.maps.Point(0, 0),
        anchor: new window.google.maps.Point(15, 30),
      });
    }
  }, [window.google]); // Run this effect when the Google Maps API is available

  const handleMarkerClick = async (mosque) => {
    setSelectedMosque(mosque);
    console.log("mosque..........", mosque);

    let lat, lng;

    if (mosque.geometry && mosque.geometry.location) {
      // Case 1: Google Places API data
      lat =
        typeof mosque.geometry.location.lat === "function"
          ? mosque.geometry.location.lat()
          : mosque.geometry.location.lat;

      lng =
        typeof mosque.geometry.location.lng === "function"
          ? mosque.geometry.location.lng()
          : mosque.geometry.location.lng;
    } else {
      // Case 2: Already saved mosque data
      lat = parseFloat(mosque.latitude);
      lng = parseFloat(mosque.longitude);
    }

    comparePointWithPolygons(lat, lng);

    // Extract image URL from the first photo if available
    let imageUrl = null;

    if (mosque?.photos?.length > 0) {
      const firstPhoto = mosque.photos[0];
  
      if (typeof firstPhoto.getUrl === "function") {
        // Case 1: `getUrl()` function is available
        imageUrl = firstPhoto.getUrl();
      } else if (firstPhoto.photo_reference) {
        // Case 2: Use `photo_reference` to build the URL
        imageUrl = `https://maps.googleapis.com/maps/api/place/photo?maxwidth=${firstPhoto.width}&photoreference=${firstPhoto.photo_reference}&key=${googleMapsApiKey}`;
      }
    }

    const mosqueData = {
      name: mosque.name,
      place_id: mosque.place_id,
      address: mosque.vicinity || "",
      geometry: { location: { lat, lng } },
      image: imageUrl,
    };

    const success = await addMosqueToList({
      type: "mosque",
      name: mosque.name,
      place_id: mosque.place_id,
      latitude: lat,
      longitude: lng,
    });

    if (success) {
      navigate("/mosques-products", { state: { selectedMosque: mosqueData } });
    }
  };

  const addMosqueToList = async (data) => {
    try {
      await AddUsePlace(data);
      return true; // Success
    } catch (error) {
      toast.error(error?.response?.data?.errors?.place_id[0]); // Show error message
      return false; // Failure
    }
  };

  const removeMosqueFromList = async (mosqueId) => {
    try {
      await DeleteUserPlace(mosqueId);
      fetchUserPlaces();
    } catch (error) {
      console.error("Error add Mosque To List:", error);
    }
  };

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
  };

  const mapOptions = {
    fullscreenControl: false,
    mapTypeControl: false,
  };

  const handleSearch = (query) => {
    const lat = query.geometry.location.lat();
    const lng = query.geometry.location.lng();
    setCenter({ lat, lng });
  };

  const comparePointWithPolygons = (lat, lng) => {
    const point = turf.point([lng, lat]);
    let isInsideAnyPolygon = false;

    polygonData.forEach((region) => {
      const coords = region.polygon_arr.map((coord) => {
        const [lat, lng] = coord.split(",").map(Number);
        return [lng, lat];
      });

      if (coords.length >= 3) {
        coords.push(coords[0]);
        const polygon = turf.polygon([coords]);

        if (turf.booleanPointInPolygon(point, polygon)) {
          isInsideAnyPolygon = true;
        }
      }
    });

    if (!isInsideAnyPolygon) {
      setShowNotAvailabePlaceModal(true);
    }
  };

  return (
    <>
      <Navbar onLanguageChange={onLanguageChange} />
      <div className="relative w-[100%]">
        <div className={`overlay ${showModal ? "active" : ""}`} />
        <LoadScript googleMapsApiKey={googleMapsApiKey} libraries={["places"]}>
          <div className={`content ${showModal ? "blur" : ""}`}>
            <div className="absolute top-4 left-1/2 transform -translate-x-1/2 z-10 w-[90%] flex flex-col md:flex-row gap-5 items-start">
              <div className="w-full md:w-1/4">
                <NewSelectedMosquesList
                  selectedMosquesList={selectedMosquesList}
                  removeMosqueFromList={removeMosqueFromList}
                  setShowOrderModal={setShowOrderModal}
                />
              </div>

              <div className="w-full md:w-1/4">
                <SearchInput
                  placeholder={t("enterTheCityOrMosqueName")}
                  onSearch={handleSearch}
                />
              </div>

              <div className="w-full md:w-1/2">
                <CategorySelector
                  handleCategoryClick={handleCategoryClick}
                  selectedCategory={selectedCategory}
                />
              </div>
            </div>

            <div
              className="fixed bottom-4 left-1/2 transform -translate-x-1/2 z-10 w-10/12 sm:w-1/2 lg:w-1/3"
              dir="rtl"
            >
              <CartOverview />
            </div>
            <div style={{ position: "relative", zIndex: 2 }}>
              {loadError ? (
                <div>{t("errorLoadingMap")}</div>
              ) : (
                <GoogleMap
                  mapContainerStyle={mapContainerStyle}
                  center={center}
                  zoom={14}
                  onLoad={handleMapLoad}
                  options={mapOptions}
                >
                  {mosques.map((mosque) => (
                    //console.log(mosque.geometry.location.lat,mosque.geometry.location.lng),
                    <Marker
                      key={mosque.place_id}
                      position={{
                        lat: mosque.geometry.location.lat,
                        lng: mosque.geometry.location.lng,
                      }}
                      icon={
                        selectedMosquesList.some(
                          (m) => m.place_id === mosque.place_id
                        )
                          ? selectedIconSize
                          : defaultIconSize
                      } // Use custom icons based on selection
                      onClick={() => handleMarkerClick(mosque)}
                    />
                  ))}
                  {selectedMosquesList.length > 0 ? (
                    selectedMosquesList.map((selectedMosque) => {
                      const lat = Number(selectedMosque.latitude);
                      const lng = Number(selectedMosque.longitude);

                      return (
                        <Marker
                          key={selectedMosque.place_id}
                          position={{
                            lat,
                            lng,
                          }}
                          icon={selectedIconSize} // Use the blue icon for selected mosques
                          onClick={() => handleMarkerClick(selectedMosque)}
                        />
                      );
                    })
                  ) : (
                    <></> // Render nothing if there are no selected mosques
                  )}

                  <OverlayView
                    position={kaabaPosition}
                    mapPaneName={OverlayView.OVERLAY_LAYER}
                  >
                    <div style={{ zIndex: 1 }}>
                      <img
                        src={kaaba}
                        alt="Kaaba"
                        style={{ width: "100px", height: "100px" }}
                      />
                    </div>
                  </OverlayView>
                </GoogleMap>
              )}
            </div>
          </div>
        </LoadScript>

        {showOrderModal && (
          <OrderModal
            selectedPlacesList={selectedMosquesList}
            setShowOrderModal={setShowOrderModal}
          />
        )}

        {showNotAvailabePlaceModal && (
          <NotAvailabePlace
            setShowNotAvailabePlaceModal={setShowNotAvailabePlaceModal}
            handleCategoryClick={handleCategoryClick}
            selectedCategory={selectedCategory}
          />
        )}
        <ToastContainer />
      </div>
    </>
  );
};

export default MosquesProductsMap;
