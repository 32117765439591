import React, {useEffect} from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faApple, faGooglePlay } from "@fortawesome/free-brands-svg-icons";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import sabeelImg from "../../assets/images/sabeelImg.png";
import { useSelector } from "react-redux";
import { QRCodeCanvas } from "qrcode.react";
import { Link } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { resetPayment } from '../../redux/paymentSlice';
import { resetOrderDetails } from '../../redux/orderSlice';
import { useNavigate  } from "react-router-dom";

const PaymentSuccess = ({ onClose }) => {
  const { t } = useTranslation();
  const paymentData = useSelector((state) => state.payment);
  const auth = useSelector(state => state.auth);
  const order = useSelector(state =>state.order)
  const dispatch = useDispatch();
  const navigate = useNavigate();
 // Trigger Snap Pixel PURCHASE event when modal is displayed
 useEffect(() => {
  const Data = {
    price: paymentData.isCouponApplied ? paymentData.totalAfterCoupon : paymentData.total.total || 0,  
    currency: paymentData?.currency || 'SAR', 
    transaction_id:'', 
    item_ids: order?.orderIds || [],
    item_category: order?.product || '', 
    number_items: order?.quantity || 0,
    uuid_c1: auth?.user?.id || '', 
    user_email: auth?.user?.email || '',
    user_phone_number: auth?.user?.phone || '',
    user_hashed_email:'', 
    user_hashed_phone_number:'', 
  };

    // Google Tag Manager Tracking
    if (window.dataLayer) {
      window.dataLayer.push({
        event: "purchase", // Custom event name for GTM
        value: Data.price,
        currency: Data.currency,
        transaction_id: Data.transaction_id,
        item_ids: Data.item_ids,
        item_category: Data.item_category,
        num_items: Data.number_items,
        user_id: Data.uuid_c1,
        user_email: Data.user_email,
        user_phone_number: Data.user_phone_number,
      });
      console.log("Purchase event sent to GTM");
    } else {
      console.warn("GTM dataLayer is not loaded");
    }

  if (window.snaptr) {
    window.snaptr('track', 'PURCHASE',Data);
  } else {
    console.warn("Snap Pixel (snaptr) is not loaded");
  }

   // TikTok Pixel Tracking
   if (window.ttq) {
    window.ttq.track('CompletePayment', {     
      value: Data.price,
      currency: Data.currency,
      content_ids: Data.item_ids,
      content_type: 'product',
      description:'Complete Payment',
      quantity: Data.number_items,});
  } else {
    console.warn("TikTok Pixel (ttq) is not loaded");
  }


}, [paymentData]);

  const closeModal = () => {
    dispatch(resetPayment());
    dispatch(resetOrderDetails()); 
    onClose();
    navigate('/');
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white rounded-lg shadow-lg text-center w-[90%] sm:w-[80%] md:w-[70%] lg:w-[60%] max-h-[90%] relative">
        <FontAwesomeIcon
          icon={faTimes}
          className="absolute top-4 right-4 text-gray-500 cursor-pointer text-lg sm:text-2xl"
          onClick={closeModal}
          aria-label="Close modal"
        />
        <div className="flex flex-col sm:flex-row">
          {/* Left section */}
          <div className="flex-1 bg-gray-50 p-4 md:p-6 flex flex-col items-center justify-center ">
            <img
              src={sabeelImg}
              alt="SabeelImage"
              className="mb-2 md:mb-4 w-[20%] md:w-60"
            />
            <h2 className="text-lg sm:text-xl font-bold text-center mb-1 sm:mb-2">
              {t("trackOrder")}
            </h2>
            <p className="text-sm sm:text-base text-center mb-2 sm:mb-4">
              {t("downloadApp")}
            </p>

            <div className="flex sm:flex-col gap-3 items-center content-center justify-center">
              <a
                href="https://apps.apple.com/us/app/sabeel-%D8%B3%D8%A8%D9%8A%D9%84/id1463403733?ls=1"
                target="_blank"
                rel="noopener noreferrer"
                className="bg-black text-white px-4 py-1 rounded flex gap-3 items-center"
              >
                <FontAwesomeIcon
                  icon={faApple}
                  className="text-lg text-white"
                  aria-label="Apple Store"
                />
                <div className="text-xs">
                  <p>{t("downloadFrom")}</p>
                  <p className="font-bold">{t("appStore")}</p>
                </div>
              </a>

              <a
                href="https://play.google.com/store/apps/details?id=com.dd.sabeel"
                target="_blank"
                rel="noopener noreferrer"
                className="bg-black text-white px-4 py-1 rounded flex gap-3 items-center"
              >
                <FontAwesomeIcon
                  icon={faGooglePlay}
                  className="text-lg text-white"
                  aria-label="Google Play Store"
                />
                <div className="text-xs">
                  <p>{t("downloadFrom")}</p>
                  <p className="font-bold">{t("googlePlay")}</p>
                </div>
              </a>
            </div>
          </div>

          <div className="border-r border-black border-opacity-[20%] md:hidden"></div>

          {/* Right section */}
          <div className="flex-1 p-6 md:p-8 justify-center items-center content-center ">
            <div className="bg-[#0804FC] rounded-lg bg-opacity-20 p-4 sm:p-6 flex flex-col items-center relative">
              <div className="absolute top-[-15px] sm:top-[-20px] flex justify-center items-center w-14 sm:w-16 h-14 sm:h-16 rounded-full border-4 border-primary-500 border-opacity-20">
                <div className="flex justify-center items-center w-full h-full bg-[#34A853] rounded-full">
                  <FontAwesomeIcon
                    icon={faCheck}
                    className="text-white text-2xl sm:text-3xl"
                  />
                </div>
              </div>

              <h3 className=" sm:text-xl font-bold mt-6 sm:mt-12">
                {t("thankYou")}
              </h3>

              <div className="mt-2 sm:mt-6 flex justify-between w-full text-sm sm:text-base">
                <span>{t("date")}</span>
                <span>{paymentData?.date}</span>
              </div>

              <div className="flex justify-between w-full text-sm sm:text-base">
                <span>{t("time")}</span>
                <span>{paymentData?.time}</span>
              </div>

              <hr className="my-2 sm:my-4 w-full border-gray-300" />

              <div className="flex justify-between w-full text-sm sm:text-base">
                <span>{t("totalBeforeVAT")}</span>
                <span>
                  {paymentData?.total?.totalBeforeVAT} {paymentData?.currency}
                </span>
              </div>

              <div className="flex justify-between w-full text-sm sm:text-base">
                <span>{t("valueAdded")}</span>
                <span>
                  {paymentData?.total?.vatAmount} {paymentData?.currency}
                </span>
              </div>

              <div className="flex justify-between w-full text-sm sm:text-base">
                <span>{t("shippingCost")}</span>
                <span>
                  {paymentData?.total?.shippingCost} {paymentData?.currency}
                </span>
              </div>

              <hr className="my-2 sm:my-4 w-full border-gray-300" />

              <div className="flex justify-between w-full text-sm sm:text-base">
                <span>{t("total")}</span>
                <span>
                  {paymentData?.isCouponApplied
                    ? paymentData?.totalAfterCoupon
                    : paymentData?.total?.total?.toFixed(2)}{" "}
                  {paymentData?.currency}
                </span>
              </div>

              {/* Display Multiple QR Codes */}
              <div className="mt-4 space-y-2">
                {paymentData?.qrCodes && paymentData?.qrCodes?.length > 0 ? (
                  paymentData?.qrCodes.map((code, index) => (
                    <div key={index} className="flex justify-center gap-2 mt-4">
                      <QRCodeCanvas value={code} size={55} />
                    </div>
                  ))
                ) : (
                 <></>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentSuccess;
