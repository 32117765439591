import React, { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { logout } from "../redux/authSlice";
import { useNavigate, Link } from "react-router-dom";
import order_home_state from "../assets/images/order_home_state.svg";
import order_mosque_state from "../assets/images/order_mosque_state.svg";
import service_type_header from "../assets/images/service_type_header.png";
//import mosque_product from "../assets/images/mosque-product.svg";
//import haram_water from "../assets/images/haram-water.svg";
import haram_water from "../assets/images/haram-product.jpeg";
import prophetproduct from "../assets/images/prophet-product.jpeg";
import mosque_water from "../assets/images/mosque-water.jpeg";
import home_products from "../assets/images/mosque-water.jpeg";
import mosque_product from "../assets/images/mosque-products.jpeg";

import { useTranslation } from "react-i18next";
import { OrderContext } from "../OrderContext"; // Adjust the import path to your context
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import ServiceCard from "../components/ServiceCard";
import CartNavigationButton from "../components/CartNavegationButton";
import CartOverview from "../components/CartOverview";

const Home = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { setIsMosqueSelected } = useContext(OrderContext); // Get setIsMosqueSelected from context
  const [showModal, setShowModal] = useState(false); // Control the visibility of modal

  // Function to handle closing the modal
  const closeModal = () => {
    setShowModal(false);
  };

  // Logout function
  const handleLogout = () => {
    dispatch(logout());
    navigate("/login"); // Navigate to login after logout
  };

  const handleServiceSelect = (isMosque) => {
    setIsMosqueSelected(isMosque); // Update the context state based on selection
  };

  return (
    <div className="bg-gray-10 flex flex-col items-center min-h-screen pb-10">
      {/* Main Container */}
      <div className="w-[90%] sm:w-[80%] bg-white rounded mb-5">
        {/* Service Type Header Image */}
        <img
          src={service_type_header}
          alt="service type header"
          className="w-full mb-5 object-cover"
        />

        {/* Choose Service Text */}
        <div className="text-center mb-5">
          <p className="font-bold text-xl sm:text-2xl">{t("chooseService")}</p>
        </div>
      </div>

      {/* Service Options */}
      <div className="flex flex-col md:flex-row justify-center items-center gap-10 md:gap-20 w-[90%] sm:w-[80%]">
        {/* Mosque Service Card */}
        <ServiceCard
          image={mosque_water}
          title={t("donateMosques")}
          link="/mosques-map"
          onClick={() => handleServiceSelect(true)}
          isNew={false} // Show "New" label
        />

        {/* Mosque Service Card */}
        <ServiceCard
          image={haram_water}
          title={t("wateringAndFeedingAlharam")}
          link="/AlHaram-products"
          onClick={() => handleServiceSelect(true)}
          isNew={true} // Show "New" label
        />
        
        <ServiceCard
          image={prophetproduct}
          title={t("wateringAndFeedingProphet")}
          link="/prophet-mosque-products"
          onClick={() => handleServiceSelect(true)}
          isNew={true} // Show "New" label
        />
        



        {/* Mosque Service Card */}
        <ServiceCard
          image={mosque_product}
          title={t("mosquesProducts")}
          link="/mosques-products-map"
          onClick={() => handleServiceSelect(true)}
          isNew={true} // Show "New" label
        />

        {/* Home Service Card 
        <ServiceCard
          image={home_products}
          title={t("donateHomes")}
          link="/map"
          onClick={() => handleServiceSelect(false)}
          isNew={false} // No "New" label
        />*/}
      </div>
      <div className="mt-10">
        <CartOverview />
      </div>
      <div className="mt-10 w-full">
        <CartNavigationButton />
      </div>

      {/* Modal */}
      {showModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white rounded-lg shadow-lg text-center w-[80%] md:w-[70%] relative">
            {/* Close button as X icon */}
            <FontAwesomeIcon
              icon={faTimes}
              className="absolute top-4 right-4 text-gray-500 cursor-pointer text-2xl"
              onClick={closeModal}
              aria-label="Close modal"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Home;
